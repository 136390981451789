import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import Reactotron                                   from "reactotron-react-js";


const axiosInstance = axios.create({
  baseURL:         process.env.REACT_APP_API_URL,
});


/**
 * Log before sending request
 */
axiosInstance.interceptors.request.use(
  function(config: AxiosRequestConfig) {
    try {
      if (config.data)
        Reactotron.log!(`request ${config.method!.toUpperCase()}:${config.url} `, JSON.parse(config.data), config);
      else
        Reactotron.log!(`request ${config.method!.toUpperCase()}:${config.url} `, config);
    }
    catch (e) {
      Reactotron.log!(e);
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  },
);


/**
 * Log after receiving response
 */
axiosInstance.interceptors.response.use(function(response: AxiosResponse) {
  Reactotron.log!(`response ${response.config.method!.toUpperCase()}:${response.config.url} `, response.data, response);
  return response;
}, function(error) {
    return Promise.reject(error);
});


export function addDefaultAuthorization(token: string): void {
  axiosInstance.defaults.headers.token = token;
}


export function removeDefaultAuthorization(): void {
  axiosInstance.defaults.headers.token = null;
}


export default axiosInstance;
