import loginSagas from "./login.sagas";
import campaignSagas from "./campaign.sagas";
import userSagas from "./user.sagas";

const sagas = {
  loginSagas,
  campaignSagas,
  userSagas,
};


export function registerSagasWithMiddleware(middleware): void {
  for (const name in sagas) {
    middleware.run(sagas[name]);
  }
}
