import React                         from "react";
import { ICreateCampaignFormValues } from "../CreateCampaignContainer";
import { useStyles }                 from "./Review.stylemaker";
import Button                                        from "@material-ui/core/Button";
import Typography                                    from "@material-ui/core/Typography";
import List                                          from "@material-ui/core/List";
import ListItem                                      from "@material-ui/core/ListItem";
import ListItemText                                  from "@material-ui/core/ListItemText";
import { Checkbox, FormControlLabel, Grid, Tooltip } from "@material-ui/core";
import PlayCircleFilledOutlinedIcon                  from "@material-ui/icons/PlayCircleFilledOutlined";
import RadioButtonUncheckedOutlinedIcon              from "@material-ui/icons/RadioButtonUncheckedOutlined";
import HelpOutlineIcon                               from "@material-ui/icons/HelpOutline";


interface ReviewInterface {
  formValues: ICreateCampaignFormValues,
  updateFormHandler: (name: string, value: string | number | null) => void
}


const Review: React.FC<ReviewInterface> = ({ formValues, updateFormHandler }) => {
  const classes = useStyles();


  /**
   * Handle the case : the user wants to instant start the campaign
   * @param event
   * @private
   */
  function _handleInstantStart(event) {
    const {name, checked} = event?.target;
    updateFormHandler(name, checked);
  }


  return (
    <>
      <Typography variant="h6" gutterBottom>
        Récapitulatif
      </Typography>
      <List disablePadding>
        <ListItem className={classes.listItem} key={"enseigneName"}>
          <ListItemText secondary={"Nom de l'enseigne"}/>
          <Typography variant="body2">{formValues?.enseigneName}</Typography>
        </ListItem>
        <ListItem className={classes.listItem} key={"enseigneURL"}>
          <ListItemText secondary={"URL de l'enseigne"}/>
          <Typography variant="body2">{formValues?.enseigneURL}</Typography>
        </ListItem>
        <ListItem className={classes.listItem} key={"productLabel"}>
          <ListItemText secondary={"Label du produit rappelé"}/>
          <Typography variant="body2">{formValues?.productLabel}</Typography>
        </ListItem>
        <ListItem className={classes.listItem} key={"productBrand"}>
          <ListItemText secondary={"Marque du produit rappelé"}/>
          <Typography variant="body2">{formValues?.productBrand}</Typography>
        </ListItem>
        <ListItem className={classes.listItem} key={"riskLabel"}>
          <ListItemText secondary={"Risque(s) du produit"}/>
          <Typography variant="body2">{formValues?.riskLabel}</Typography>
        </ListItem>
        {/*<ListItem className={classes.listItem} key={"productBatchNumbers"}>*/}
        {/*  <ListItemText secondary={"Numéro de lots à rappeler"}/>*/}
        {/*  <Typography variant="body2">{formValues?.productBatchNumbers?.filter(Boolean)?.join(";").replaceAll(";", " - ")}</Typography>*/}
        {/*</ListItem>*/}
        <ListItem className={classes.listItem} key={"clientDataFile"}>
          <ListItemText secondary={"Fichier clients à utiliser"}/>
          <Typography variant="body2">{formValues?.clientDataFile?.name}</Typography>
        </ListItem>
      </List>
    </>
  );
};

export default Review;
