import React, { useContext, useEffect, useState } from "react";
import { useHistory }                             from "react-router-dom";
import Fab                                        from "@material-ui/core/Fab";
import EditIcon                                   from "@material-ui/icons/Edit";
import { useGlobalStyles, useTabStyles }          from "./Dashboard.stylemaker";
import AppBar                                     from "../../Commons/AppBar/AppBar";
import Tabs, { Tab }      from "../../Commons/Tabs/Tabs";
import CurrentDataTable   from "../../Uncommons/DataTables/CurrentDataTable/CurrentDataTable";
import CompletedDataTable from "../../Uncommons/DataTables/CompletedDataTable/CompletedDataTable";
import UnstartedDataTable from "../../Uncommons/DataTables/UnstartedDataTable/UnstartedDataTable";
import { RoutePath }      from "../../../constants/routePath";
import { REQUEST_STATUS }                         from "../../../constants/requestStatus";
import { AlertSeverity }                          from "../../../constants/alertSeverity";
import { connect }                                from "react-redux";
import { RequestStatusSnackbarContext }           from "../../../contexts/RequestStatusSnackbarContext";


const tabs: Array<Tab> = [
  {
    id:    "unstarted-campaigns-tab",
    label: "Non commencées",
  },
  {
    id:    "current-campaigns-tab",
    label: "En cours",
  },
  {
    id:    "completed-campaigns-tab",
    label: "Terminées",
  },
];


interface ContentInterface {
  selectedIndexTab: number
}


const Content: React.FC<ContentInterface> = ({ selectedIndexTab }) => {
  return {
  // @ts-ignore
    "unstarted-campaigns-tab": <UnstartedDataTable/>,
    "current-campaigns-tab":   <CurrentDataTable/>,
    "completed-campaigns-tab": <CompletedDataTable/>,
  }[tabs[selectedIndexTab].id];
};


interface IDashboardProps {
  deleteCampaignRequestStatusState: REQUEST_STATUS,
}


/**
 * Dashboard Functional Component
 * @param deleteCampaignRequestStatusState
 * @constructor
 */
const Dashboard: React.FC<IDashboardProps> = ({ deleteCampaignRequestStatusState }) => {

  const classes                                 = useGlobalStyles();
  const tabClasses                              = useTabStyles();
  const history                                 = useHistory();
  const { display: RSSCDisplay }                = useContext(RequestStatusSnackbarContext);
  const [selectedIndexTab, setSelectedIndexTab] = useState(0);


  /**
   * Handle user click when he wants to add a campaign
   * @private
   */
  function _handleClick() {
    history.push(RoutePath.CreateCampaign);
  }


  /**
   * After a delete
   */
  useEffect(() => {
    if (deleteCampaignRequestStatusState === REQUEST_STATUS.FETCHED) {
      RSSCDisplay?.(AlertSeverity.Success, "La campagne a été supprimée avec succès !");
    }
  }, [deleteCampaignRequestStatusState]);


  return (
    <div className={classes.container}>
      <AppBar/>
      <Fab variant="extended" className={classes.fab} onClick={_handleClick}>
        <EditIcon/>
        Ajouter une campagne
      </Fab>
      <Tabs classNames={tabClasses} value={selectedIndexTab} onChange={(event, newValue) => { setSelectedIndexTab(newValue); }} tabs={tabs}/>
      <Content selectedIndexTab={selectedIndexTab}/>
    </div>
  );
};

const mapStateToProps = state => ( {
  deleteCampaignRequestStatusState: state?.campaign?.deleteCampaignRequestStatus,
} );

export default connect(mapStateToProps)(Dashboard);
