import React                             from "react";
import { isNumber }                      from "lodash";
import { Card, CardContent, Typography } from "@material-ui/core";
import { useStyles }                     from "./StatCard.stylemaker";


interface IStatCard {
  title: string,
  value: string | number,
}


const StatCard: React.FC<IStatCard> = ({ title, value }) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <Typography variant="h5" component="h5" className={classes.titleContainer}>
          {title} :
        </Typography>
        <Typography className={classes.valueContainer} color={isNumber(value) && value > 0 ? "primary" : "textSecondary"}>
          {value}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default StatCard;
