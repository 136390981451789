import { compact, flow, head, isEqual, sortBy } from "lodash";


// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const headerParsingCallback = headers => headers.map(header => {
  switch (header) {
    case "id":
      return "externalId";
    default:
      return header;
  }
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function CSVToJSON(csvFile: File, separator?: string, verifiedHeaders?: string[], headerParsingCallback?: any): Promise<any> {
  return new Promise((resolve, reject) => {
    const reader  = new FileReader();
    reader.onload = function(event: ProgressEvent<FileReader>) {
      const csvStringified = event?.target?.result;
      if (typeof csvStringified === "string") {
        const data = flow([
          csvString => compact(csvString.split("\n")),
          lines => lines.map(line => line.split(separator || ";")),
          csvArray => {
            const escapeSpecificCharactersRegex = /[^a-z0-9\-\+\.]/gi;

            // @ts-ignore
            let headers: Array<string>        = head(csvArray).map(header => header.replaceAll(escapeSpecificCharactersRegex, ""));

            if (typeof headerParsingCallback === "function") {
              headers = headerParsingCallback(headers);
            }

            if (verifiedHeaders && Array.isArray(verifiedHeaders)) {
              const headersEquality = isEqual(sortBy(verifiedHeaders), sortBy(headers));
              if (!headersEquality) reject(`Les en-têtes du fichier CSV ne correspondent pas avec les en-têtes suivantes: ${verifiedHeaders.join(", ")}. En-têtes du fichier: ${headers.join(", ")}`);
            }

            return csvArray
              .slice(1)
              .map(line => line.reduce(
                (accObject, cell, index) => {
                  const header      = headers[index];
                  accObject[header] = cell && cell.replaceAll(escapeSpecificCharactersRegex, "");
                  return accObject;
                },
                {},
              ));
          },
        ])(csvStringified);
        resolve(data);
      }
    };
    reader.readAsText(csvFile);
    reader.onerror = function() { reject("Une erreur est survenue sur la lecture du fichier CSV."); };
  });
}


// flow([
//   csvString => compact(csvString.split('\n')),
//   lines => lines.map(line => line.split(separator)),
//   (csvArray) => {
//     const headers: any = head(csvArray);
//     return csvArray.slice(1)
//                    .map(line => line.reduce((accObject, cell, index) => {
//                      const header = headers[index] && headers[index].replace(/"/g, '');
//                      accObject[header] = cell && cell.replace(/"/g, '');
//                      return accObject;
//                    }, {}));
//   },
// ]
