import { createRoutine } from "redux-saga-routines";
import { createAction }  from "@reduxjs/toolkit";

export const getCampaignsRoutine            = createRoutine("CAMPAIGN/GET/ALL");
export const getCampaignRoutine             = createRoutine("CAMPAIGN/GET/ONE");
export const startCampaignRoutine           = createRoutine("CAMPAIGN/START/ONE");
export const createCampaignRoutine          = createRoutine("CAMPAIGN/CREATE/ONE");
export const updateCampaignRoutine          = createRoutine("CAMPAIGN/PATCH/ONE");
export const deleteCampaignRoutine          = createRoutine("CAMPAIGN/DELETE/ONE");
export const resetCampaignState             = createAction("CAMPAIGN/RESET_STATE");
export const updateUsersReadCampaignState   = createAction("CAMPAIGN/UPDATE/READ_CAMPAIGN/USERS");
export const addUserReadCampaignState       = createAction("CAMPAIGN/UPDATE/READ_CAMPAIGN/ADD/USER");
export const removeUserReadCampaign         = createAction("CAMPAIGN/UPDATE/READ_CAMPAIGN/REMOVE/USER");
export const resetUserListReadCampaignState = createAction("CAMPAIGN/UPDATE/READ_CAMPAIGN/REMOVE/USERS");
export const updateCampaignStartedState     = createAction("CAMPAIGN/UPDATE/STARTED");
