import React, { useState }                       from "react";
import { TablePagination as MUITablePagination } from "@material-ui/core";
import { useStyles }                             from "./TablePagination.stylemaker";


interface TablePaginationInterface {
  rows: Array<unknown>,
  alwaysShow?: boolean,
}


const TablePagination: React.FC<TablePaginationInterface> = ({ rows }) => {
  const classes                       = useStyles();
  const [page, setPage]               = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(20);

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <MUITablePagination
      backIconButtonText={"Page précédente"}
      className={classes.container}
      component="div"
      count={rows.length}
      labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count !== -1 ? count : `more than ${to}`}`}
      labelRowsPerPage={"Campagnes affichées par page"}
      nextIconButtonText={"Page suivante"}
      page={page}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={[5, 10, 15, 20]}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
};

export default TablePagination;
