import * as React                                                 from "react";
import { useStyles }                                              from "../DataTable.stylemaker";
import { useMount }                                               from "../../../../hooks/useMount";
import { connect, useDispatch }                                   from "react-redux";
import { getCampaignsRoutine }                                    from "../../../../actions/campaign.actions";
import { Table, TableBody, TableCell, TableContainer, TableHead } from "@material-ui/core";
import { StyledTableCell }                                        from "../../../Commons/TableCell/TableCell";
import { REQUEST_STATUS }                                         from "../../../../constants/requestStatus";
import TableNoContent                                             from "../../../Commons/TableNoContent/TableNoContent";
import TablePagination                                            from "../../../Commons/TablePagination/TablePagination";
import { ICampaignModel }                                         from "../../../../models/ICampaignModel";
import Copyright                                                  from "../../../Commons/Copyright/Copyright";
import TableRow                                                   from "../../../Commons/TableRow/TableRow";


interface CompletedDataTableInterface {
  fetchCampaignStatusState: REQUEST_STATUS,
  fetchErrorMessage: string | null,
  completedCampaignsState: Array<ICampaignModel>
}


const CompletedDataTable: React.FC<CompletedDataTableInterface> = ({
                                                                     fetchCampaignStatusState,
                                                                     fetchErrorMessage,
                                                                     completedCampaignsState,
                                                                   }) => {

  const classes              = useStyles();
  const dispatch             = useDispatch();
  const isCompletedCampaigns = fetchCampaignStatusState === REQUEST_STATUS.FETCHED && !!completedCampaignsState.length;

  useMount(() => {
    dispatch(getCampaignsRoutine.trigger());
  });

  return (
    <>
      <div className={classes.container}>
        <TableContainer>
          <Table size="small" aria-label="unstarted campaign table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Enseigne</StyledTableCell>
                <StyledTableCell align="center">Produit</StyledTableCell>
                <StyledTableCell align="center">Marque</StyledTableCell>
              </TableRow>
            </TableHead>
            {
              isCompletedCampaigns &&
              <TableBody>
                {
                  completedCampaignsState.map((row) => (
                    <TableRow key={row.externalId} onClick={() => {console.debug("TODO")}} rowValue={row} className={classes.row}>
                      <TableCell component="th" scope="row">
                        {row.enseigneLabel}
                      </TableCell>
                      <TableCell align="center">{row.productLabel}</TableCell>
                      <TableCell align="center">{row.productBrand}</TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            }
          </Table>
        </TableContainer>
        <TableNoContent requestStatus={fetchCampaignStatusState} message={!isCompletedCampaigns ? "Pas de campagnes terminées" : fetchErrorMessage}/>
        <TablePagination rows={completedCampaignsState}/>
      </div>
      <Copyright/>
    </>
  );
};

CompletedDataTable.defaultProps = {
  completedCampaignsState: [],
};

const mapStateToProps: (state: any) => CompletedDataTableInterface = state => ( {
  fetchCampaignStatusState: state?.campaign?.fetchCampaignsRequestStatus,
  fetchErrorMessage:        state?.campaign?.fetchCampaignsErrorMessage,
  completedCampaignsState:  state?.campaign?.completedCampaigns,
} );

export default connect(mapStateToProps)(CompletedDataTable);
