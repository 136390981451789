// noinspection JSDeprecatedSymbols
import { CaseReducers }      from "@reduxjs/toolkit";
import { UserInitialState }  from "./index";
import { REQUEST_STATUS }    from "../../constants/requestStatus";
import { createUserRoutine } from "../../actions/user.actions";


export interface IDeleteUserInitialState {
  deleteUserErrorMessage: string | null;
  deleteUserRequestStatus: REQUEST_STATUS;
}


export const DELETE_USER_INITIAL_STATE: IDeleteUserInitialState = {
  deleteUserErrorMessage:      null,
  deleteUserRequestStatus:     REQUEST_STATUS.INITIAL,
};

// noinspection JSDeprecatedSymbols
export const deleteUserPartReducer: CaseReducers<UserInitialState, any> = {
  [createUserRoutine.REQUEST]: (state, action) => {
    state.deleteUserRequestStatus = REQUEST_STATUS.FETCHING;
  },
  [createUserRoutine.SUCCESS]: (state, action) => {
    if (action?.payload?.success) {
      state.deleteUserRequestStatus     = REQUEST_STATUS.FETCHED;
    }
    else {
      state.deleteUserRequestStatus     = REQUEST_STATUS.FAILED;
      state.deleteUserErrorMessage      = action?.payload?.message;
    }
  },
  [createUserRoutine.FAILURE]: (state, action) => {
    state.deleteUserRequestStatus     = REQUEST_STATUS.FAILED;
    state.deleteUserErrorMessage      = action?.payload?.message;
  },
};
