import React, { useContext, useEffect } from "react";
import MuiAlert                         from "@material-ui/lab/Alert";
import { Snackbar }                     from "@material-ui/core";
import { AlertSeverity }                from "../../../constants/alertSeverity";
import { RequestStatusSnackbarContext } from "../../../contexts/RequestStatusSnackbarContext";


function Alert(props): JSX.Element {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const RequestStatusSnackbar: React.FC = () => {
  const RSSCData                   = useContext(RequestStatusSnackbarContext);
  const [open, setOpen]           = React.useState<boolean>(false);
  const [severity, setSeverity]   = React.useState<AlertSeverity|null>(null);
  const [alertText, setAlertText] = React.useState<string>("");


  useEffect(() => {
    if (RSSCData?.open !== open) {
      setOpen(RSSCData.open);
      if (!RSSCData?.open) { // don't update alertSeverity and alertText
        return;
      }
    }
    if (RSSCData?.alertSeverity !== severity) setSeverity(RSSCData.alertSeverity);
    if (RSSCData?.alertText !== alertText) setAlertText(RSSCData.alertText);
  }, [RSSCData]);


  /**
   * Handle user wants to close the snackbar
   * @param event
   * @param reason
   * @private
   */
  function _handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    RSSCData?.reset?.();
    setOpen(false);
  }


  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={_handleClose}>
      <Alert onClose={_handleClose} severity={severity}>
        {alertText}
      </Alert>
    </Snackbar>
  );
};

export default RequestStatusSnackbar;
