// noinspection JSDeprecatedSymbols
import { CaseReducers }       from "@reduxjs/toolkit";
import { UserInitialState }   from "./index";
import { REQUEST_STATUS }     from "../../constants/requestStatus";
import { updateUsersRoutine } from "../../actions/user.actions";
import { remove }             from "lodash";


export interface IUpdateUsersInitialState {
  updateUsersErrorMessage: string | null;
  updateUsersRequestStatus: REQUEST_STATUS;
  enqueueUpdatingUsersRequests: Array<string>;
}


export const UPDATE_USERS_INITIAL_STATE: IUpdateUsersInitialState = {
  updateUsersErrorMessage:      null,
  updateUsersRequestStatus:     REQUEST_STATUS.INITIAL,
  enqueueUpdatingUsersRequests: [],
};

// noinspection JSDeprecatedSymbols
export const updateUsersPartReducer: CaseReducers<UserInitialState, any> = {
  [updateUsersRoutine.REQUEST]: (state, action) => {
    state.updateUsersRequestStatus = REQUEST_STATUS.FETCHING;
    state.enqueueUpdatingUsersRequests.push(action?.payload?.campaignId);
  },
  [updateUsersRoutine.SUCCESS]: (state, action) => {
    state.updateUsersRequestStatus     = REQUEST_STATUS.FETCHED;
    state.enqueueUpdatingUsersRequests = remove(state.enqueueUpdatingUsersRequests, action?.payload?.campaignId);
  },
  [updateUsersRoutine.FAILURE]: (state, action) => {
    state.updateUsersRequestStatus     = REQUEST_STATUS.FAILED;
    state.updateUsersErrorMessage      = action?.payload?.message;
    state.enqueueUpdatingUsersRequests = remove(state.enqueueUpdatingUsersRequests, action?.payload?.campaignId);
  },
};
