// noinspection JSDeprecatedSymbols
import { CaseReducers }         from "@reduxjs/toolkit";
import { CampaignInitialState } from ".";
import { startCampaignRoutine } from "../../actions/campaign.actions";
import { REQUEST_STATUS }       from "../../constants/requestStatus";


export interface IStartCampaignInitialState {
  startCampaignErrorMessage: string | null;
  startCampaignRequestStatus: REQUEST_STATUS;
}


export const START_CAMPAIGN_INITIAL_STATE: IStartCampaignInitialState = {
  startCampaignErrorMessage:  null,
  startCampaignRequestStatus: REQUEST_STATUS.INITIAL,
};


// noinspection JSDeprecatedSymbols
export const startCampaignPartReducer: CaseReducers<CampaignInitialState, any> = {
  [startCampaignRoutine.REQUEST]: state => {
    state.startCampaignRequestStatus = REQUEST_STATUS.FETCHING;
  },
  [startCampaignRoutine.SUCCESS]: (state, action) => {
    try {
      state.startCampaignRequestStatus = REQUEST_STATUS.FETCHED;
    }
    catch (e) {
      state.startCampaignRequestStatus = REQUEST_STATUS.FAILED;
      state.startCampaignErrorMessage  = e.message;
    }
  },
  [startCampaignRoutine.FAILURE]: (state, action) => {
    state.startCampaignRequestStatus = REQUEST_STATUS.FAILED;
    state.startCampaignErrorMessage  = action?.payload?.message;
  },
};
