import React                from "react";
import PropTypes            from "prop-types";
import { REQUEST_STATUS }   from "../../../constants/requestStatus";
import Typography           from "@material-ui/core/Typography/Typography";
import { CircularProgress } from "@material-ui/core";
import { useStyles }        from "./TableNoContent.stylemaker";

interface TableNoContentInterface {
  requestStatus: REQUEST_STATUS
  message: string|null
}

const TableNoContent: React.FC<TableNoContentInterface> = ({ requestStatus, message }) => {
  const classes = useStyles();
  return (
    <div className={classes.noContentContainer}>
      {
        requestStatus === REQUEST_STATUS.INITIAL &&
        <Typography variant="overline" display="block" gutterBottom>
          Pas de données
        </Typography>
      }
      {
        requestStatus === REQUEST_STATUS.FETCHING &&
        <CircularProgress/>
      }
      {
        requestStatus === REQUEST_STATUS.FAILED &&
        <Typography color="primary" variant="overline" display="block" gutterBottom>
          { message }
        </Typography>
      }
      {
        requestStatus === REQUEST_STATUS.FETCHED && message &&
        <Typography variant="overline" display="block" gutterBottom>
          { message }
        </Typography>
      }
    </div>
  );
};

export default TableNoContent;
