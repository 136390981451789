import React, { useState }                            from "react";
import uniqId                                         from "uniqid";
import Typography                                     from "@material-ui/core/Typography";
import Grid                                           from "@material-ui/core/Grid";
import { Button, FormHelperText, TextField, Tooltip } from "@material-ui/core";
import { useStyles }                                  from "./DirectCallForm.stylemaker";
import HelpOutlineIcon                                from "@material-ui/icons/HelpOutline";


interface IDirectCallForm {
  onSubmit: (data: { phoneNumber: string, externalId: string }) => void
}


const DirectCallForm: React.FC<IDirectCallForm> = ({ onSubmit }) => {
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>("");
  const classes                       = useStyles();


  /**
   * Handle input change
   * @param event
   * @private
   */
  function _handleChange(event) {
    const { value } = event?.target;
    setPhoneNumber(value);
  }


  /**
   * Handle submit
   * @private
   */
  function _handleSubmit() {
    if (phoneNumber) {
      onSubmit({
        phoneNumber,
        externalId: `directcall_${uniqId()}`,
      });
      setPhoneNumber("");
    }
  }


  return (
    <>
      <Typography variant="h6" gutterBottom>
        Programmer un appel direct
      </Typography>
      <Grid container spacing={3} className={classes.formContainer}>
        <Grid item xs={12}>
          <TextField
            required
            id="phoneNumber"
            name="phoneNumber"
            label="Numéro de téléphone à rappeler"
            value={phoneNumber}
            type={"tel"}
            fullWidth
            onChange={_handleChange}
          />
          <FormHelperText className={classes.formHelper}>
            <i>Permet de programmmer un appel direct</i>
            <Tooltip title="Peut être utilisé pour simuler un test.">
              <HelpOutlineIcon/>
            </Tooltip>
          </FormHelperText>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color={"primary"}
            disabled={!phoneNumber}
            onClick={_handleSubmit}
          >
            Appeler
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default DirectCallForm;
