import { makeStyles } from "@material-ui/core/styles";

export const useGlobalStyles = makeStyles((theme) => ( {
  container: {
    width:  "100vw",
    height: "100vh",
  },
  fab:       {
    position:             "fixed",
    right:                "30px",
    bottom:               "30px",
    color:                "white",
    "background-color":   "#e2001a",
    "& .MuiSvgIcon-root": {
      paddingRight: "5px",
    },
    "&:hover":            {
      color:              "white",
      "background-color": "#d50018",
    },
  },
  bottomNav: {
    position: "fixed",
    bottom: 0,
    width: "100vw",
  },
} ));

export const useTabStyles = makeStyles((theme) => ( {
  header: {
    backgroundColor: "#333 !important"
  },
  "& .MuiTabs-indicator": {
    "background-color":   "#e2001a",
  }
} ));
