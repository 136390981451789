import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ( {
  noContentContainer:   {
    flex:           1,
    display:        "flex",
    justifyContent: "center",
    alignItems:    "center",
  },
} ));
