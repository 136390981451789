import { CircularProgress, Snackbar } from "@material-ui/core";
import React, { useEffect }           from "react";
import { connect }   from "react-redux";
import { useStyles } from "./UserRequestStatusSnackbar.stylemaker";


interface IUserRequestStatusSnackbarProps {
  enqueueCreatingUsersRequestsState: Array<string>,
  enqueueUpdatingUsersRequestsState: Array<string>,
  enqueueCreatingUserRequestsState: Array<string>,
}


const UserRequestStatusSnackbar: React.FC<IUserRequestStatusSnackbarProps> = ({ enqueueCreatingUsersRequestsState, enqueueUpdatingUsersRequestsState, enqueueCreatingUserRequestsState }) => {
  const [open, setOpen] = React.useState(true);
  const classes = useStyles();

  useEffect(() => {
    if ((enqueueCreatingUsersRequestsState.length || enqueueUpdatingUsersRequestsState.length || enqueueCreatingUserRequestsState.length) && !open) setOpen(true);
    else if (!enqueueCreatingUsersRequestsState.length && !enqueueUpdatingUsersRequestsState.length && !enqueueCreatingUserRequestsState.length && open) setOpen(false);
  }, [enqueueCreatingUsersRequestsState, enqueueUpdatingUsersRequestsState]);

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical:   "bottom",
          horizontal: "left",
        }}
        className={classes.snackbarContainer}
        message={
          <>
            <CircularProgress />
            {`Ajout d'utilisateurs (${enqueueCreatingUsersRequestsState.length + enqueueUpdatingUsersRequestsState.length + enqueueCreatingUserRequestsState.length} processus en cours)`}
          </>
        }
        open={open}
      />
    </div>
  );
};
const mapStateToProps                                                      = state => ( {
  enqueueCreatingUsersRequestsState: state?.user?.enqueueCreatingUsersRequests,
  enqueueUpdatingUsersRequestsState: state?.user?.enqueueUpdatingUsersRequests,
  enqueueCreatingUserRequestsState: state?.user?.enqueueCreatingUserRequests,
} );
export default connect(mapStateToProps)(UserRequestStatusSnackbar);
