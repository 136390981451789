import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ( {
  appBar:         {
    position: "relative",
  },
  layout:         {
    width:                                              "auto",
    marginLeft:                                         theme.spacing(2),
    marginRight:                                        theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width:       600,
      marginLeft:  "auto",
      marginRight: "auto",
    },
  },
  paper:          {
    marginTop:                                          theme.spacing(3),
    marginBottom:                                       theme.spacing(3),
    padding:                                            theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop:    theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding:      theme.spacing(3),
    },
  },
  stepper:        {
    padding: theme.spacing(3, 0, 5),
  },
  buttons:        {
    display:        "flex",
    justifyContent: "flex-end",
  },
  button:         {
    marginLeft: theme.spacing(1),
  },
  buttonSuccessWrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color:      theme.palette.primary.main,
    position:   "absolute",
    top:        "50%",
    left:       "50%",
    marginTop:  -12,
    marginLeft: -12,
  },
} ));
