import React                         from "react";
import Grid                          from "@material-ui/core/Grid";
import Typography                    from "@material-ui/core/Typography";
import TextField                     from "@material-ui/core/TextField";
import { ICreateCampaignFormValues } from "../../../Views/CreateCampaign/CreateCampaignContainer";
import { useStyles }                 from "./CampaignDetailsForm.stylemaker";


/**
 * Form default values Interface
 */
export interface ICampaignDetailsFormValues {
  enseigneName?: string,
  enseigneURL?: string,
  productLabel?: string,
  productBrand?: string,
  riskLabel?: string,
  // productBatchNumbers?: Array<string>,
}


/**
 * Form default values
 */
export const CampaignDetailsFormDefaultValues = {
  enseigneName:        "",
  enseigneURL:         "",
  productLabel:        "",
  productBrand:        "",
  riskLabel:           "",
  // productBatchNumbers: [],
};

/**
 * CampaignDetailsForm Props Interface
 */
interface CampaignDetailsFormInterface {
  formValues: ICreateCampaignFormValues,
  updateFormHandler: (name: string, value: string[] | string | number | null) => void,
}


/**
 * CampaignDetailsForm Functional Component
 * @param formValues
 * @param updateFormHandler
 * @constructor
 */
const CampaignDetailsForm: React.FC<CampaignDetailsFormInterface> = ({ formValues, updateFormHandler }) => {

  const classes = useStyles();

  /**
   * Global change handler
   * @param event
   * @private
   */
  function _handleChange(event): void {
    const { name, value } = event?.target;
    updateFormHandler(name, value);
  }


  /**
   * Specific change handler for product batch numbers
   * @param event
   * @private
   */
  // function _handleProductBatchNumbersChange(event) {
  //   const { name, value }         = event?.target;
  //   const excludeRegex            = /^\;|[^a-z0-9\;]|\;(?=\;)/gi; // keep only letters, numbers and one semicolon between product batch numbers
  //   const newValue: Array<string> = value.replaceAll(excludeRegex, "").split(";");
  //   updateFormHandler(name, newValue);
  // }


  return (
    <>
      <Typography variant="h6" gutterBottom>
        Détails de la campagne
      </Typography>
      <Grid container spacing={3} className={classes.formContainer}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="companyName"
            name="enseigneName"
            label="Nom de l'enseigne"
            value={formValues?.enseigneName}
            fullWidth
            onChange={_handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="companyURL"
            name="enseigneURL"
            label="URL de l'enseigne"
            value={formValues?.enseigneURL}
            fullWidth
            onChange={_handleChange}
            helperText={"Lien vers l’affichette du rappel produit identifiant les lots concernés"}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="productLabel"
            name="productLabel"
            label="Label du produit rappelé"
            value={formValues?.productLabel}
            fullWidth
            onChange={_handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="productBrand"
            name="productBrand"
            label="Marque du produit rappelé"
            value={formValues?.productBrand}
            fullWidth
            onChange={_handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="riskLabel"
            name="riskLabel"
            label="Risque(s) du produit"
            value={formValues?.riskLabel}
            fullWidth
            multiline
            rows={2}
            onChange={_handleChange}
          />
        </Grid>
        {/*<Grid item xs={12}>*/}
        {/*  <TextField*/}
        {/*    id="productBatchNumbers"*/}
        {/*    name="productBatchNumbers"*/}
        {/*    label="Numéro de lots à rappeler"*/}
        {/*    fullWidth*/}
        {/*    multiline*/}
        {/*    rows={2}*/}
        {/*    value={formValues?.productBatchNumbers?.join(";")}*/}
        {/*    onChange={_handleProductBatchNumbersChange}*/}
        {/*    helperText={"Seuls les caractères suivants sont autorisés : les lettres de A à Z sans accents (en minuscule ou en majuscule), les chiffres de 0 à 9 et les points virgules pour séparer chaque numéro de lots. E.g. \"ABC12345;123987D7\" "}*/}
        {/*  />*/}
        {/*</Grid>*/}
      </Grid>
    </>
  );
};
export default CampaignDetailsForm;
