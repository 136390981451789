import React                                                     from "react";
import { bindActionCreators }                                    from "redux";
import { connect }                                               from "react-redux";
import { useHistory }                                            from "react-router-dom";
import { AppBar as MaterialAppBar, Button, Toolbar, Typography } from "@material-ui/core";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useStyles }                                             from "./AppBar.stylemaker";
import { ReactComponent as MousquetairesLogo }                   from "./../../../assets/img/logo.svg";
import { logout }                                                from "../../../actions/app.actions";
import { RoutePath }                                             from "../../../constants/routePath";

interface AppBarInterface {
  goBack?: boolean,
  goBackLabel?: string,
  logoutAction: () => void
}

const AppBar: React.FC<AppBarInterface> = ({ goBack, goBackLabel, logoutAction }) => {

  const classes       = useStyles();
  const history       = useHistory();
  const _handleLogout = () => {
    // @ts-ignore
    logoutAction();
    history.push(RoutePath.Root);
  };

  function _handleBack() {
    history.goBack();
  }

  return (
    <MaterialAppBar position="static" className={classes.header}>
      <Toolbar className={classes.toolbar}>
        <Typography variant="h1" className={classes.title}>
          {
            goBack &&
            <Button disableElevation variant="contained" onClick={_handleBack}>
              <ArrowBackIosIcon/>
              { goBackLabel }
            </Button>
          }
          <MousquetairesLogo/>
          Les Campagnes Mousquetaires
        </Typography>
        <Button
          className={classes.logoutButton}
          disableElevation
          onClick={_handleLogout}
          variant="contained"
        >
          Se déconnecter
        </Button>
      </Toolbar>
    </MaterialAppBar>
  );
};

AppBar.defaultProps = {
  goBack: false,
  goBackLabel: "dashboard",
}

const mapDispatchToProps = dispatch => ( {
  logoutAction: bindActionCreators(logout, dispatch),
} );

export default connect(null, mapDispatchToProps)(AppBar);
