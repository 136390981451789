import React, { useContext, useEffect }                                                      from "react";
import { bindActionCreators }                                                                from "redux";
import { connect }                                                                           from "react-redux";
import { useHistory }                                                                        from "react-router-dom";
import cx                                                                                    from "classnames";
import CssBaseline                                                                           from "@material-ui/core/CssBaseline";
import Paper                                                                                 from "@material-ui/core/Paper";
import Stepper                                                                               from "@material-ui/core/Stepper";
import Step                                                                                  from "@material-ui/core/Step";
import StepLabel                                                                             from "@material-ui/core/StepLabel";
import Button                                                                                from "@material-ui/core/Button";
import Typography                                                                            from "@material-ui/core/Typography";
import { CircularProgress }                                                                  from "@material-ui/core";
import CampaignDetailsForm, { CampaignDetailsFormDefaultValues, ICampaignDetailsFormValues } from "../../Commons/Form/CampaignDetailsForm/CampaignDetailsForm";
import UploadUserFileForm, { IUploadUserFileFormValues, UploadUserFileFormDefaultValues }    from "../../Commons/Form/UploadUserFileForm/UploadUserFileForm";
import AppBar                                                                                from "../../Commons/AppBar/AppBar";
import Copyright                                                                             from "../../Commons/Copyright/Copyright";
import { useStyles }                                                                         from "./CreateCampaignContainer.stylemaker";
import { checkCampaignDetailsForm, checkUserForm }                                           from "./CreateCampaignContainer.helper";
import Review                                                                                from "./Review/Review";
import { createCampaignRoutine }                                                             from "../../../actions/campaign.actions";
import { REQUEST_STATUS }                                                                    from "../../../constants/requestStatus";
import { RoutePath }                                                                         from "../../../constants/routePath";
import { RequestStatusSnackbarContext }                                                      from "../../../contexts/RequestStatusSnackbarContext";
import { AlertSeverity }                                                                     from "../../../constants/alertSeverity";


/**
 * Form default values Interface
 */
export interface ICreateCampaignFormValues extends ICampaignDetailsFormValues, IUploadUserFileFormValues {
  instantStart?: boolean,
}


/**
 * Steps for MUI Stepper
 */
const steps = ["Informations générales", "Clients à contacter", "Récapitulatif"];


/**
 * Form default values
 */
const CreateCampaignFormDefaultValues = { // prevent uncontrolled input to controlled error
  ...CampaignDetailsFormDefaultValues,
  ...UploadUserFileFormDefaultValues,
  instantStart: false,
};


/**
 * Create Campaign Container Props Interface
 */
interface ICreateCampaignContainerProps {
  createCampaignStatus: REQUEST_STATUS,
  createCampaignAction: (formValues: ICreateCampaignFormValues) => void
}


/**
 * Create Campaign Container Functional Component
 * @param createCampaignAction
 * @param createCampaignStatus
 * @constructor
 */
const CreateCampaignContainer: React.FC<ICreateCampaignContainerProps> = ({ createCampaignAction, createCampaignStatus }) => {
  const classes                                     = useStyles();
  const history                                     = useHistory();
  const { display: RSSCDisplay }                    = useContext(RequestStatusSnackbarContext);
  const [activeStep, setActiveStep]                 = React.useState<number>(0);
  const [formValues, setFormValues]                 = React.useState<ICreateCampaignFormValues>(CreateCampaignFormDefaultValues);
  const [isStepContentValid, setIsStepContentValid] = React.useState<boolean>(false);
  const [submitted, setSubmitted]                   = React.useState<boolean>(false);
  const isLastStep: boolean                         = activeStep === steps.length - 1;


  useEffect(() => {
    if (createCampaignStatus === REQUEST_STATUS.FETCHED) {
      history.push(RoutePath.Dashboard);
      RSSCDisplay?.(AlertSeverity.Success, "La campagne a été ajoutée avec succès !");
    }
    else if (createCampaignStatus === REQUEST_STATUS.FAILED) {
      setSubmitted(false);
    }
  }, [history, createCampaignStatus]);


  /**
   * Handle navigation next
   * @private
   */
  function _handleNext(): void {
    setActiveStep(activeStep + 1);
  }


  /**
   * Handle navigation back
   * @private
   */
  function _handleBack(): void {
    setActiveStep(activeStep - 1);
  }


  /**
   * Handle user submit
   * @private
   */
  function _handleSubmit(): void {
    setSubmitted(true);
    createCampaignAction(formValues);
  }


  /**
   * Update form value
   * @param name
   * @param value
   */
  function handleUpdateForm(name: string, value: string[] | string | number | null): void {
    setFormValues({ ...formValues, [name]: value });
  }


  /**
   * Define the step component and the state of next button
   * @param step
   * @private
   */
  function _getStepContent(step) {
    let lIsStepContentValid: boolean;
    switch (step) {
      case 0:
        lIsStepContentValid = checkCampaignDetailsForm(formValues);
        if (isStepContentValid !== lIsStepContentValid) setIsStepContentValid(lIsStepContentValid);
        return <CampaignDetailsForm formValues={formValues} updateFormHandler={handleUpdateForm}/>;
      case 1:
        const isFileWrongType: boolean = !!formValues?.clientDataFile && formValues?.clientDataFile?.type !== "text/csv";
        lIsStepContentValid            = checkUserForm(formValues, isFileWrongType);
        if (isStepContentValid !== lIsStepContentValid) setIsStepContentValid(lIsStepContentValid);
        return <UploadUserFileForm isFileWrongType={isFileWrongType} selectedFile={formValues?.clientDataFile} updateFormHandler={handleUpdateForm}/>;
      case 2:
        return <Review formValues={formValues} updateFormHandler={handleUpdateForm}/>;
      default:
        throw new Error("Unknown step");
    }
  }


  return (
    <>
      <CssBaseline/>
      <AppBar goBack={true}/>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center">
            Créer une campagne
          </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {
              steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))
            }
          </Stepper>
          <>
            {_getStepContent(activeStep)}
            <div className={classes.buttons}>
              {
                activeStep !== 0 &&
                <Button onClick={_handleBack} className={classes.button}>
                  Étape précédente
                </Button>
              }
              <div className={classes.buttonSuccessWrapper}>
                <Button
                  className={cx(classes.button)}
                  color="primary"
                  disabled={!isStepContentValid || submitted}
                  onClick={isLastStep ? _handleSubmit : _handleNext}
                  variant="contained"
                >
                  {
                    isLastStep
                    ? !submitted
                      ? "Valider la campagne"
                      : "Envoi..."
                    : "Suivant"
                  }
                </Button>
                {submitted && <CircularProgress size={24} className={classes.buttonProgress}/>}
              </div>
            </div>
          </>
        </Paper>
        <Copyright/>
      </main>
    </>
  );
};

const mapStateToProps = state => ( {
  createCampaignErrorMessage: state.campaign.createCampaignErrorMessage,
  createCampaignStatus:       state.campaign.createCampaignStatus,
} );

const mapDispatchToProps = dispatch => ( {
  createCampaignAction: bindActionCreators(createCampaignRoutine, dispatch),
} );

export default connect(mapStateToProps, mapDispatchToProps)(CreateCampaignContainer);
