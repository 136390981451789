import React                                                                  from "react";
import { AppBar as MaterialAppBar, Tab as MaterialTab, Tabs as MaterialTabs } from "@material-ui/core";


export interface Tab {
  id: string,
  label: string,
}


interface BottomTabInterface {
  ariaLabel?: string,
  classNames?: {
    header?: string,
    tab?: string,
    tabBar?: string,
  }
  onChange: (event: React.ChangeEvent<unknown>, value: any) => void,
  tabs: Array<Tab>,
  value: string | number,
}


const Tabs: React.FC<BottomTabInterface> = ({
                                              ariaLabel,
                                              classNames,
                                              onChange,
                                              tabs,
                                              value,
                                            }) => {
  if (!tabs.length) return null;

  return (
    <MaterialAppBar className={classNames?.header} position="static">
      <MaterialTabs aria-label={ariaLabel} className={classNames?.tabBar} onChange={onChange} value={value}>
        {
          tabs.map((tab, index) => (
            <MaterialTab className={classNames?.tab} id={tab.id} key={index} label={tab.label}/>
          ))
        }
      </MaterialTabs>
    </MaterialAppBar>
  );
};

Tabs.defaultProps = {
  ariaLabel: "tabs",
};

export default Tabs;
