import React                                                                           from "react";
import { connect, useDispatch }                                                        from "react-redux";
import { RouteComponentProps, useHistory }                                             from "react-router-dom";
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, Tooltip } from "@material-ui/core";
import DeleteIcon                                                                      from "@material-ui/icons/Delete";
import FindInPageIcon                                                                  from "@material-ui/icons/FindInPage";
import PlayArrowIcon                                                        from "@material-ui/icons/PlayArrow";
import { useStyles }                                                        from "../DataTable.stylemaker";
import { deleteCampaignRoutine, getCampaignsRoutine, startCampaignRoutine } from "../../../../actions/campaign.actions";
import { REQUEST_STATUS }                                                   from "../../../../constants/requestStatus";
import { RoutePath }                                                        from "../../../../constants/routePath";
import { useMount }                                                         from "../../../../hooks/useMount";
import { ICampaignModel }                                                   from "../../../../models/ICampaignModel";
import Copyright                                                            from "../../../Commons/Copyright/Copyright";
import { StyledTableCell }                                                  from "../../../Commons/TableCell/TableCell";
import TableNoContent                                                       from "../../../Commons/TableNoContent/TableNoContent";
import TablePagination                                                      from "../../../Commons/TablePagination/TablePagination";
import TableRow                                                             from "../../../Commons/TableRow/TableRow";
import DeleteCampaignModal                                                  from "../../DeleteCampaignModal/DeleteCampaignModal";
import { bindActionCreators }                                               from "redux";
import StartCampaignModal                                                   from "../../StartCampaignModal/StartCampaignModal";


/**
 * UnstartedDataTable Props Interface
 */
interface IUnstartedDataTableProps extends RouteComponentProps<Record<string, any>> {
  deleteCampaignAction: (id: string | number) => void,
  startCampaignAction: (id: string | number) => void,
  fetchCampaignStatusState: REQUEST_STATUS,
  fetchErrorMessage: string | null,
  unStartedCampaignsState: Array<ICampaignModel>
}


/**
 * UnstartedDataTable Functional Component
 * @param deleteCampaignAction
 * @param fetchCampaignStatusState
 * @param fetchErrorMessage
 * @param match
 * @param startCampaignAction
 * @param unStartedCampaignsState
 * @constructor
 */
const UnstartedDataTable: React.FC<IUnstartedDataTableProps> = ({
                                                                  deleteCampaignAction,
                                                                  fetchCampaignStatusState,
                                                                  fetchErrorMessage,
                                                                  match,
                                                                  startCampaignAction,
                                                                  unStartedCampaignsState,
                                                                }) => {

  const classes                                             = useStyles();
  const history                                             = useHistory();
  const dispatch                                            = useDispatch();
  const [showDeleteModal, setShowDeleteModal]               = React.useState<boolean>(false);
  const [showStartCampaignModal, setShowStartCampaignModal] = React.useState<boolean>(false);
  const [selectedRowId, setSelectedRowId]                   = React.useState<string>("");
  const isUnstartedCampaigns                                = fetchCampaignStatusState === REQUEST_STATUS.FETCHED && !!unStartedCampaignsState.length;


  useMount(() => {
    dispatch(getCampaignsRoutine.trigger());
  });


  /**
   * Handle start campaign
   * @private
   */
  function _handleStart(event, rowId) {
    event.stopPropagation();
    setShowStartCampaignModal(true);
    setSelectedRowId(rowId);
  }


  /**
   * Handle start campaign
   * @private
   */
  function handleStart() {
    startCampaignAction(selectedRowId);
    setShowStartCampaignModal(false);
  }


  /**
   * Handle use case: user wants edit campaign
   * @param event
   * @param rowValue
   * @private
   */
  function _handleEditCampaignClick(event?: unknown, rowValue?: ICampaignModel) {
    if (rowValue?.id) history.push(RoutePath.EditCampaign.replace(":id", `${rowValue.id}`));
  }


  /**
   * Local handle delete (first use case)
   * @param event
   * @param rowId
   * @private
   */
  function _handleDelete(event, rowId) {
    event.stopPropagation();
    setShowDeleteModal(true);
    setSelectedRowId(rowId);
  }


  /**
   * Definitive handle delete (second use case, in modal)
   */
  function handleDelete() {
    setShowDeleteModal(false);
    deleteCampaignAction(selectedRowId);
    dispatch(getCampaignsRoutine.trigger());
  }


  return (
    <>
      <div className={classes.container}>
        <TableContainer>
          <Table size="small" aria-label="unstarted campaign table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Enseigne</StyledTableCell>
                <StyledTableCell align="center">Produit</StyledTableCell>
                <StyledTableCell align="center">Marque</StyledTableCell>
                <StyledTableCell align="center"/>
              </TableRow>
            </TableHead>
            {
              isUnstartedCampaigns &&
              <TableBody>
                {
                  unStartedCampaignsState.map((row) => (
                    <TableRow key={row.externalId} onClick={_handleEditCampaignClick} rowValue={row} className={classes.row}>
                      <TableCell component="th" scope="row">
                        {row.enseigneLabel}
                      </TableCell>
                      <TableCell align="center">{row.productLabel}</TableCell>
                      <TableCell align="center">{row.productBrand}</TableCell>
                      <TableCell align="center">
                        <Tooltip title="Démarrer la campagne">
                          <IconButton aria-label="start campaign" size="small" onClick={event => { _handleStart(event, row.id); }}>
                            <PlayArrowIcon fontSize="inherit"/>
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Voir les détails de la campagne">
                          <IconButton aria-label="show campaign details" size="small" color={"primary"}>
                            <FindInPageIcon fontSize="inherit"/>
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Supprimer la campagne">
                          <IconButton aria-label="delete campaign" size="small" onClick={event => { _handleDelete(event, row.id); }}>
                            <DeleteIcon fontSize="inherit"/>
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            }
          </Table>
        </TableContainer>
        <TableNoContent requestStatus={fetchCampaignStatusState} message={!isUnstartedCampaigns ? "Pas de campagnes en non-démarrées" : fetchErrorMessage}/>
        <TablePagination rows={unStartedCampaignsState} alwaysShow={true}/>
      </div>
      <Copyright/>
      <DeleteCampaignModal open={showDeleteModal} onClose={() => setShowDeleteModal(false)} onPositiveAction={handleDelete}/>
      <StartCampaignModal open={showStartCampaignModal} onClose={() => setShowStartCampaignModal(false)} onPositiveAction={handleStart}/>
    </>
  );
};

UnstartedDataTable.defaultProps = {
  unStartedCampaignsState: [],
};

const mapStateToProps = state => ( {
  fetchCampaignStatusState: state?.campaign?.fetchCampaignsRequestStatus,
  fetchErrorMessage:        state?.campaign?.fetchCampaignsErrorMessage,
  unStartedCampaignsState:  state?.campaign?.unStartedCampaigns,
} );

const mapDispatchToProps = dispatch => ( {
  deleteCampaignAction: bindActionCreators(deleteCampaignRoutine, dispatch),
  startCampaignAction:  bindActionCreators(startCampaignRoutine, dispatch),
} );

export default connect(mapStateToProps, mapDispatchToProps)(UnstartedDataTable);
