import React                                                                           from "react";
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, Tooltip } from "@material-ui/core";
import TableRow                                                                        from "../../../Commons/TableRow/TableRow";
import { StyledTableCell }                                                             from "../../../Commons/TableCell/TableCell";
import TablePagination                                                                 from "../../../Commons/TablePagination/TablePagination";
import { useStyles }                                                                   from "../DataTable.stylemaker";
import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
import DeleteIcon                                                                      from "@material-ui/icons/Delete";
import { IUserModel }                                                                  from "../../../../models/IUserModel";


interface IUserDataTableProps {
  users?: Array<IUserModel>,
  onDelete: (userExternalId: string) => void,
}


const UserDataTable: React.FC<IUserDataTableProps> = ({ users, onDelete }) => {
  const classes = useStyles();


  /**
   * Handle delete
   * @param event
   * @param userId
   * @private
   */
  function _handleDelete(event, userId): void {
    onDelete(userId);
  }


  return (
    <>
      <div>
        <TableContainer>
          <Table aria-label="unstarted campaign table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Id</StyledTableCell>
                <StyledTableCell align="center">Téléphone</StyledTableCell>
                <StyledTableCell align="center">Statut</StyledTableCell>
                <StyledTableCell align="center">Action(s)</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                ( users || [] ).map((user: IUserModel) => (
                  <TableRow key={user.externalId} rowValue={user} className={classes.row}>
                    <TableCell component="th" scope="row">
                      {user.externalId}
                    </TableCell>
                    <TableCell align="center">{user.phoneNumberId}</TableCell>
                    <TableCell align="center">{user.currentState}</TableCell>
                    <TableCell align="center">
                      <Tooltip title="Supprimer l'utilisateur'">
                        <IconButton aria-label="delete user" size="small" onClick={event => { _handleDelete(event, user._id); }}>
                          <DeleteIcon fontSize="inherit"/>
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination rows={( users || [] )}/>
      </div>
    </>
  );
};

UserDataTable.defaultProps = {
  users: [],
};

export default UserDataTable;
