import React                 from "react";
import { Button, TextField } from "@material-ui/core";
import Typography            from "@material-ui/core/Typography";
import Grid                  from "@material-ui/core/Grid";
import { useStyles }         from "./UserForm.stylemaker";

/**
 * Form default values
 */
export const UserFormDefaultValues = {
  clientId:          "",
  clientPhoneNumber: "",
};


export interface IUserFormValues {
  clientId: string,
  clientPhoneNumber: string,
}


export interface IUserFormProps {
  formValues: IUserFormValues,
  onChange: (name: string, value: string[] | string | number | null) => void,
  onSubmit: (user: IUserFormValues) => void,
}


const UserForm: React.FC<IUserFormProps> = ({ formValues, onChange, onSubmit }) => {
  const classes       = useStyles();
  const missingValues = !formValues?.clientId || !formValues?.clientPhoneNumber;


  /**
   * Global change handler
   * @param event
   * @private
   */
  function _handleChange(event): void {
    const { name, value } = event?.target;
    onChange(name, value);
  }


  /**
   * Handle user submit
   * @private
   */
  function _handleSubmit() {
    if (missingValues) return;
    onSubmit(formValues);
  }


  return (
    <>
      <Typography variant="h6" gutterBottom>
        Ajouter un client
      </Typography>
      <Grid container spacing={3} className={classes.formContainer}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="clientId"
            name="clientId"
            label="Identifiant du client"
            value={formValues?.clientId}
            fullWidth
            onChange={_handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="clientPhoneNumber"
            name="clientPhoneNumber"
            label="Numéro de téléphone du client"
            value={formValues?.clientPhoneNumber}
            fullWidth
            onChange={_handleChange}
          />
        </Grid>
        <Grid item xs={12} style={{ display: "flex", justifyContent: "end" }}>
          <Button
            variant="contained"
            color={"primary"}
            disabled={missingValues}
            onClick={_handleSubmit}
          >
            Ajouter
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default UserForm;
