import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ( {
  container: {
    width:                          "100vw",
    height:                         "calc(100vh - 64px - 48px - 48px - 30px - 30px)", // less header, tabs, add campaign button height and position (from bottom), and a simulated margin
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 61px - 48px - 48px - 30px - 30px)", // less header, tabs, add campaign button height and position (from bottom), and a simulated margin
    },
    display:                        "flex",
    flexDirection:                  "column",
    justifyContent:                 "space-between",
    marginBottom:                   "35px",
  },
  content:   {
    flex:           1,
    display:        "flex",
    justifyContent: "center",
    alignItems:     "center",
  },
  row:       {
    "&:hover": {
      backgroundColor: "#e6e6e6",
      cursor: "pointer",
    },
  },
} ));
