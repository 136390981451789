import { combineReducers } from "redux";
import appReducer          from "./app/app.reducers";
import campaignReducer     from "./campaign";
import userReducer         from "./user";

export default combineReducers({
  app:      appReducer,
  campaign: campaignReducer,
  user:     userReducer,
});
