import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware                      from "redux-saga";
import reduxMulti                                from "redux-multi";
import reducers                                  from "./../reducers";
import Reactotron                                from "../config/ReactotronConfig";
import { registerSagasWithMiddleware }           from "../sagas";

/**
 * Configure our store
 * Enable redux devtools
 * @return {*}
 */
const configureStore = () => {
  const sagaMonitor               = Reactotron.createSagaMonitor!();
  const sagaMiddleware            = createSagaMiddleware({ sagaMonitor }); // give an object and add to the store to having all necessary store's information
  const middlewares               = [reduxMulti, sagaMiddleware];
  // @ts-ignore
  const composeEnhancers          = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const createStoreWithMiddleware = composeEnhancers(applyMiddleware(...middlewares), Reactotron.createEnhancer!())(createStore);
  const store                     = createStoreWithMiddleware(reducers); // store
  registerSagasWithMiddleware(sagaMiddleware);
  return store;
};

export default configureStore();
