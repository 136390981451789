import { createReducer }                                                                         from "@reduxjs/toolkit";
import { CREATE_CAMPAIGN_INITIAL_STATE, createCampaignPartReducer, ICreateCampaignInitialState } from "./createCampaignPart.reducer";
import { GET_CAMPAIGNS_INITIAL_STATE, getCampaignsPartReducer, IGetCampaignsInitialState }       from "./getCampaignsPart.reducer";
import { GET_CAMPAIGN_INITIAL_STATE, getCampaignPartReducer, IGetCampaignInitialState }          from "./getCampaignPart.reducer";
import { DELETE_CAMPAIGN_INITIAL_STATE, deleteCampaignPartReducer, IDeleteCampaignInitialState } from "./deleteCampaignPart.reducer";
import { resetCampaignState, updateCampaignStartedState }                                        from "../../actions/campaign.actions";
import { IUpdateCampaignInitialState, UPDATE_CAMPAIGN_INITIAL_STATE, updateCampaignPartReducer } from "./updateCampaignPart.reducer";
import { IStartCampaignInitialState, START_CAMPAIGN_INITIAL_STATE, startCampaignPartReducer }    from "./startCampaignPart.reducer";


export type CampaignInitialState =
  IGetCampaignsInitialState
  & ICreateCampaignInitialState
  & IGetCampaignInitialState
  & IDeleteCampaignInitialState
  & IUpdateCampaignInitialState
  & IStartCampaignInitialState;


export const INITIAL_STATE: CampaignInitialState = {
  ...GET_CAMPAIGNS_INITIAL_STATE,
  ...CREATE_CAMPAIGN_INITIAL_STATE,
  ...GET_CAMPAIGN_INITIAL_STATE,
  ...DELETE_CAMPAIGN_INITIAL_STATE,
  ...UPDATE_CAMPAIGN_INITIAL_STATE,
  ...START_CAMPAIGN_INITIAL_STATE,
};


const campaignReducer = createReducer(INITIAL_STATE, {
  ...getCampaignsPartReducer,
  ...createCampaignPartReducer,
  ...getCampaignPartReducer,
  ...deleteCampaignPartReducer,
  ...updateCampaignPartReducer,
  ...startCampaignPartReducer,
  [resetCampaignState.type]:         state => ( {
    ...state,
    ...GET_CAMPAIGN_INITIAL_STATE,
    ...DELETE_CAMPAIGN_INITIAL_STATE,
  } ),
  [updateCampaignStartedState.type]: (state, action) => {
    const { campaignId } = action?.payload;
    if (campaignId) {
      const unstartedCampaignIndex = state.unStartedCampaigns?.findIndex(unStartedCampaign => unStartedCampaign._id === campaignId);
      if (state.unStartedCampaigns?.length && typeof unstartedCampaignIndex === "number" && unstartedCampaignIndex !== -1) {
        state.currentCampaigns?.push(state.unStartedCampaigns[unstartedCampaignIndex]);
        state.unStartedCampaigns.splice(unstartedCampaignIndex, 1);
      }
      if (state.readCampaign?._id === campaignId) {
        state.readCampaign = { ...state.readCampaign, started: true };
      }
    }
    else console.error("Missing campaign id in ", updateCampaignStartedState.type);
  },
});

// @ts-ignore
export default campaignReducer;
