import { all, call, fork, put, takeLatest }                                               from "redux-saga/effects";
import { addUserReadCampaignState, removeUserReadCampaign, updateUsersReadCampaignState } from "../actions/campaign.actions";
import { CSVToJSON, headerParsingCallback }                                               from "../utils/requestHelpers/userListParser";
import { createUserRoutine, createUsersRoutine, deleteUserRoutine, updateUsersRoutine } from "../actions/user.actions";
import { deleteUserRequest, putUserRequest, putUsersRequest }                           from "../requests/userRequests";


/**
 ******************************************************************************************************************* \
 * USERS/CREATE/USERS
 */

function* createUsers({ payload }) {
  const campaignId = payload?.campaignId;
  try {
    yield put(createUsersRoutine.request({ campaignId }));

    const stringifiedClientDataFile = yield call(CSVToJSON, payload?.clientDataFile, undefined, ["externalId", "phoneNumber"], headerParsingCallback);
    const data                      = {
      campaign: campaignId,
      users:    stringifiedClientDataFile,
    };

    yield call(putUsersRequest, data);
    yield put(createUsersRoutine.success({ campaignId }));
  }
  catch (e) {
    console.error(e);
    yield put(createUsersRoutine.failure({ campaignId, message: e.message }));
  }
  yield put(createUsersRoutine.fulfill());
}


function* watchCreateUsers() {
  // @ts-ignore
  yield takeLatest(createUsersRoutine.TRIGGER, createUsers);
}

/**
 ******************************************************************************************************************* \
 * USERS/CREATE/USERS
 */

function* createUser({ payload }) {
  const userExternalId = payload?.clientId;
  try {
    yield put(createUserRoutine.request({ userExternalId }));

    const data                      = {
      campaign: payload?.campaignId,
      externalId:    payload?.clientId,
      phoneNumber:    payload?.clientPhoneNumber,
    };

    const res = yield call(putUserRequest, data);
    yield put(createUserRoutine.success({ userExternalId }));
    // @ts-ignore
    yield put(addUserReadCampaignState({ campaignId: payload?.campaignId, user: res?.data?.result?.user }));
  }
  catch (e) {
    console.error(e);
    yield put(createUserRoutine.failure({ userExternalId, message: e.message }));
  }
  yield put(createUserRoutine.fulfill());
}


function* watchCreateUser() {
  // @ts-ignore
  yield takeLatest(createUserRoutine.TRIGGER, createUser);
}


/**
 ******************************************************************************************************************* \
 * USERS/DELETE/USER
 */

function* deleteUser({ payload }) {
  try {
    const { userId } = payload;
    yield put(deleteUserRoutine.request());
    const res = yield call(deleteUserRequest, userId);
    yield put(deleteUserRoutine.success());
    if (res?.data?.success) {
      // @ts-ignore
      yield put(removeUserReadCampaign(payload));
    }
  }
  catch (e) {
    console.error(e);
    yield put(deleteUserRoutine.failure({ message: e.message }));
  }
  yield put(deleteUserRoutine.fulfill());
}


function* watchDeleteUser() {
  // @ts-ignore
  yield takeLatest(deleteUserRoutine.TRIGGER, deleteUser);
}



/**
 ******************************************************************************************************************* \
 * USERS/CREATE/USERS
 */

function* updateUsers({ payload }) {
  const campaignId = payload?.campaignId;
  try {
    yield put(updateUsersRoutine.request({ campaignId }));

    const stringifiedClientDataFile = yield call(CSVToJSON, payload?.clientDataFile, undefined, ["externalId", "phoneNumber"], headerParsingCallback);
    const data                      = {
      campaign: campaignId,
      users:    stringifiedClientDataFile,
    };

    const res = yield call(putUsersRequest, data);
    yield put(updateUsersRoutine.success({ campaignId }));
    // @ts-ignore
    yield put(updateUsersReadCampaignState({ campaignId, users: res?.data?.result }));
  }
  catch (e) {
    console.error(e);
    yield put(updateUsersRoutine.failure({ campaignId, message: e.message }));
  }
  yield put(updateUsersRoutine.fulfill());
}


function* watchUpdateUsers() {
  // @ts-ignore
  yield takeLatest(updateUsersRoutine.TRIGGER, updateUsers);
}


/**
 ******************************************************************************************************************* \
 * root
 */

export default function* root() {
  yield all([
    fork(watchCreateUsers),
    fork(watchCreateUser),
    fork(watchDeleteUser),
    fork(watchUpdateUsers),
  ]);
}
