import React                             from "react";
import { Provider }                      from "react-redux";
import { ThemeProvider }                 from "@material-ui/core/styles";
import configureStore                    from "../store/configureStore";
import Router                            from "./Router/Router";
import "./App.css";
import { theme }                         from "../config/Theme";
import RequestStatusSnackbar             from "./Uncommons/RequestStatusSnackbar/RequestStatusSnackbar";
import { RequestStatusSnackbarProvider } from "../contexts/RequestStatusSnackbarContext";
import { SnackbarProvider }              from "notistack";
import UserRequestStatusSnackbar         from "./Uncommons/UserRequestStatusSnackbar/UserRequestStatusSnackbar";


function App(): any {
  return (
    <Provider store={configureStore}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={12}>
          <RequestStatusSnackbarProvider>
            <RequestStatusSnackbar/>
            <Router/>
          </RequestStatusSnackbarProvider>
          <UserRequestStatusSnackbar/>
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  );
}


export default App;
