import React          from "react";
import Container      from "@material-ui/core/Container";
import { Typography } from "@material-ui/core";
import Link           from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ( {
  root: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  }
} ));

const Page404: React.FC = () => {
  const classes = useStyles();
  return (
    <Container component="main" maxWidth="xs" className={classes.root}>
      <Typography variant={"h1"} component={"h1"}>404</Typography>
      <Typography variant={"body2"} component={"span"}>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        Oups, la page n'a pas été trouvée. Retourner sur le <Link href="#" variant="body2">Tableau de bord</Link>.
      </Typography>
    </Container>
  );
};

Page404.propTypes = {

};

export default Page404;
