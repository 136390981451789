import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ( {
  formContainer:      {
    marginTop: 12,
  },
  uploadBtnContainer: {
    display:                 "flex",
    alignItems:              "center",
    flexGrow:                "unset",
    paddingBottom:           "unset !important",
    "& .MuiButton-root":     {
      marginRight: 10,
      flex:        0.9,
    },
    "& .MuiTypography-root": {
      flex:              1.1,
      "& .MuiLink-root": {
        cursor: "pointer",
      },
    },
  },
  uploadIcon:         {
    marginRight: 10,
  },
  formHelper:         {
    paddingLeft: 13,
    marginTop: 5,
    display: "flex",
    alignItems: "center",
    "& > svg": {
      width: "0.7em",
      height: "0.7em",
      marginLeft: theme.spacing(0.3),
      cursor: "pointer",
    }
  },
} ));
