import React, { useContext, useEffect }                                                           from "react";
import { bindActionCreators }                                                                     from "redux";
import { connect }                                                                                from "react-redux";
import { RouteComponentProps, useHistory }                                                        from "react-router-dom";
import { isEqual }                                                                                from "lodash";
import cx                                                                                         from "classnames";
import { Button, Chip, CircularProgress, Container, Fab, Typography }                             from "@material-ui/core";
import Paper                                                                                      from "@material-ui/core/Paper";
import SaveIcon                                                                                   from "@material-ui/icons/Save";
import PlayCircleFilledIcon                                                                       from "@material-ui/icons/PlayCircleFilled";
import PauseCircleFilledIcon                                                                      from "@material-ui/icons/PauseCircleFilled";
import EventAvailableIcon                                                                         from "@material-ui/icons/EventAvailable";
import { useStyles }                                                                              from "./EditCampaignContainer.stylemaker";
import CampaignDetailsForm, { CampaignDetailsFormDefaultValues, ICampaignDetailsFormValues }      from "../../Commons/Form/CampaignDetailsForm/CampaignDetailsForm";
import AppBar                                                                                     from "../../Commons/AppBar/AppBar";
import { useMount }                                                                                                               from "../../../hooks/useMount";
import { deleteCampaignRoutine, getCampaignRoutine, resetUserListReadCampaignState, startCampaignRoutine, updateCampaignRoutine } from "../../../actions/campaign.actions";
import UploadUserFileForm, { IUploadUserFileFormValues, UploadUserFileFormDefaultValues }                                         from "../../Commons/Form/UploadUserFileForm/UploadUserFileForm";
import DeleteCampaignModal                                                                        from "../../Uncommons/DeleteCampaignModal/DeleteCampaignModal";
import { REQUEST_STATUS }                                                                         from "../../../constants/requestStatus";
import { RoutePath }                                                                              from "../../../constants/routePath";
import { AlertSeverity }                                                                          from "../../../constants/alertSeverity";
import { RequestStatusSnackbarContext }                     from "../../../contexts/RequestStatusSnackbarContext";
import UserDataTable                                        from "../../Uncommons/DataTables/UserDataTable/UserDataTable";
import { IExtendedCampaignModel }                           from "../../../reducers/campaign/getCampaignPart.reducer";
import StartCampaignModal                                   from "../../Uncommons/StartCampaignModal/StartCampaignModal";
import Copyright                                            from "../../Commons/Copyright/Copyright";
import UserForm, { IUserFormValues, UserFormDefaultValues } from "../../Commons/Form/UserForm/UserForm";
import { createUserRoutine, deleteUserRoutine }             from "../../../actions/user.actions";
import CampaignStatsChart                                   from "../../Uncommons/CampaignStatsChart/CampaignStatsChart";
import DeleteUserModal                                      from "../../Uncommons/DeleteUserModal/DeleteUserModal";
import DirectCallForm                                               from "../../Commons/Form/DirectCallForm/DirectCallForm";
import { campaignDirectCallRequest, deleteAllUsersCampaignRequest } from "../../../requests/campaignRequests";
import Grid                                                         from "@material-ui/core/Grid";
import DeleteAllUsersModal                                  from "../../Uncommons/DeleteAllUsersModal/DeleteAllUsersModal";


interface ILoaderInterface {
  classes?: Record<string, string>
}


const Loader: React.FC<ILoaderInterface> = ({ classes }) => {
  return (
    <div className={classes?.loader}>
      <CircularProgress/>
      <Typography variant="overline" display="block" align={"center"} gutterBottom className={classes?.loaderText}>
        Le chargement des données peut prendre du temps
      </Typography>
    </div>
  );
};


/**
 * Form default values Interface
 */
interface ICampaignFormDefaultValues extends ICampaignDetailsFormValues, IUploadUserFileFormValues {
  id?: string | number,
  started?: boolean,
  completed?: boolean,
}


/**
 * Form default values
 */
const EditCampaignFormDefaultValues: ICampaignFormDefaultValues = { // prevent uncontrolled input to controlled error
  ...CampaignDetailsFormDefaultValues,
  ...UploadUserFileFormDefaultValues,
  started:   false,
  completed: false,
};


/**
 * Edit Campaign Container Props Interface
 */
interface IEditCampaignContainerProps extends RouteComponentProps<Record<string, any>> {
  createUserAction: (user: IUserFormValues & { campaignId: string | number }) => void,
  deleteUserAction: (payload: { userId: string, campaignId: string | number }) => void,
  deleteCampaignAction: (id: string | number) => void,
  deleteUserListCampaignAction: (id: string | number) => void,
  getCampaignAction: (id: string | number) => void,
  startCampaignAction: (id: string | number) => void,
  updateCampaignAction: () => void,
  readCampaign: IExtendedCampaignModel | null,
  deleteCampaignErrorMessageState: string | null,
  deleteCampaignRequestStatusState: REQUEST_STATUS,
  fetchCampaignRequestStatusState: REQUEST_STATUS,
  createUserRequestStatusState: REQUEST_STATUS,
}


/**
 * EditCampaignContainer functional component
 * @param deleteCampaignAction
 * @param deleteCampaignErrorMessageState
 * @param deleteCampaignRequestStatusState
 * @param fetchCampaignRequestStatusState
 * @param getCampaignAction
 * @param match
 * @param readCampaign
 * @param updateCampaignAction
 * @param enqueueUpdatingUsersRequestsState
 * @constructor
 */
const EditCampaignContainer: React.FC<IEditCampaignContainerProps> = ({
  createUserAction,
  deleteUserAction,
  deleteCampaignAction,
  deleteUserListCampaignAction,
  deleteCampaignErrorMessageState,
  deleteCampaignRequestStatusState,
  fetchCampaignRequestStatusState,
  createUserRequestStatusState,
  getCampaignAction,
  startCampaignAction,
  match,
  readCampaign,
  updateCampaignAction,
}) => {
  const classes                                             = useStyles();
  const [formValues, setFormValues]                         = React.useState<ICampaignFormDefaultValues>(EditCampaignFormDefaultValues);
  const [formattedReadCampaign, setFormattedReadCampaign]   = React.useState<ICampaignFormDefaultValues>(EditCampaignFormDefaultValues);
  const [isUnstartedCampaign, setIsUnstartedCampaign]       = React.useState<boolean>(!EditCampaignFormDefaultValues?.started);
  const [loading, setLoading]                               = React.useState<boolean>(true);
  const [userToDelete, setUserToDelete]                     = React.useState<string | null>(null);
  const [showStartCampaignModal, setShowStartCampaignModal] = React.useState<boolean>(false);
  const [showDeleteUserModal, setShowDeleteUserModal]       = React.useState<boolean>(false);
  const [showDeleteUserListModal, setShowDeleteUserListModal]       = React.useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal]               = React.useState<boolean>(false);
  const [campaignStatusLabel, setCampaignStatusLabel]       = React.useState<string>("");
  const [campaignStatusIcon, setCampaignStatusIcon]         = React.useState<any>(<PauseCircleFilledIcon/>);
  const [newUserFormValues, setNewUserFormValues]           = React.useState<IUserFormValues>(UserFormDefaultValues);
  const history                                             = useHistory();
  const { display: RSSCDisplay }                            = useContext(RequestStatusSnackbarContext);
  const savingAuthorized                                    = !isEqual(formValues, formattedReadCampaign);
  const isFileWrongType: boolean                            = !!formValues?.clientDataFile && formValues?.clientDataFile?.type !== "text/csv";

  useMount(() => {
    getCampaignAction(match?.params?.id);
  });

  useEffect(() => {
    if (fetchCampaignRequestStatusState === REQUEST_STATUS.FETCHED) setLoading(false);
  }, [fetchCampaignRequestStatusState]);

  /**
   * Set data
   */
  useEffect(() => {
    const formattedData = {
      id:           readCampaign?._id,
      enseigneName: readCampaign?.enseigneLabel || EditCampaignFormDefaultValues.enseigneName,
      enseigneURL:  readCampaign?.enseigneUrl || EditCampaignFormDefaultValues.enseigneURL,
      productLabel: readCampaign?.productLabel || EditCampaignFormDefaultValues.productLabel,
      productBrand: readCampaign?.productBrand || EditCampaignFormDefaultValues.productBrand,
      riskLabel:    readCampaign?.riskLabel || EditCampaignFormDefaultValues.riskLabel,
      // productBatchNumbers: readCampaign?.productBatchNumbers || EditCampaignFormDefaultValues.productBatchNumbers,
      started:      readCampaign?.started,
      completed:    readCampaign?.completed,
    };
    setIsUnstartedCampaign(!readCampaign?.started);
    setFormattedReadCampaign(formattedData);
    setFormValues(formattedData);
  }, [readCampaign]);


  /**
   * Set status displayed data
   */
  useEffect(() => {
    if (!formValues?.started) {
      setCampaignStatusLabel("Campagne pas encore démarrée");
      setCampaignStatusIcon(<PauseCircleFilledIcon/>);
    }
    else if (!formValues?.completed) {
      setCampaignStatusLabel("Campagne en cours");
      setCampaignStatusIcon(<PlayCircleFilledIcon/>);
    }
    else {
      setCampaignStatusLabel("Campagne terminée");
      setCampaignStatusIcon(<EventAvailableIcon/>);
    }
  }, [formValues]);


  /**
   * After a delete
   */
  useEffect(() => {
    if (deleteCampaignRequestStatusState === REQUEST_STATUS.FETCHED) {
      history.push(RoutePath.Dashboard);
      // RSSCDisplay?.(AlertSeverity.Success, "La campagne a été supprimée avec succès !"); WARNING: do in dashboard (multi delete) // TODO : (when have the time) group that w/ dashboard
    }
    else if (deleteCampaignRequestStatusState === REQUEST_STATUS.FAILED) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      RSSCDisplay?.(AlertSeverity.Error, deleteCampaignErrorMessageState!);
    }
  }, [deleteCampaignRequestStatusState]);

  /**
   * After create one user
   */
  useEffect(() => {
    if (createUserRequestStatusState === REQUEST_STATUS.FETCHED) {
      setNewUserFormValues(UserFormDefaultValues);
    }
  }, [createUserRequestStatusState]);


  /**
   * Handle start campaign
   * @private
   */
  function _handleStart() {
    setShowStartCampaignModal(true);
  }


  /**
   * Handle start campaign
   * @private
   */
  function handleStart() {
    startCampaignAction(match?.params?.id);
    setShowStartCampaignModal(false);
  }


  /**
   * Update form value
   * @param name
   * @param value
   */
  function handleUpdateFormValues(name: string, value: string[] | string | number | null): void {
    setFormValues({ ...formValues, [name]: value });
  }


  /**
   * Update new user form value
   * @param name
   * @param value
   */
  function handleUserFormUpdate(name: string, value: string[] | string | number | null) {
    setNewUserFormValues({ ...newUserFormValues, [name]: value });
  }


  /**
   * Definitive handle delete (second use case, in modal)
   */
  function handleDelete() {
    setShowDeleteModal(false);
    deleteCampaignAction(match?.params?.id);
  }


  /**
   * Handle user delete from table
   * @param userId
   */
  function handleUserDelete(userId: string): void {
    setUserToDelete(userId);
    setShowDeleteUserModal(true);
  }


  /**
   * Handle user delete local
   */
  function _handleUserDelete(): void {
    if (!userToDelete) return;
    deleteUserAction({ userId: userToDelete, campaignId: match?.params?.id });
    setShowDeleteUserModal(false);
  }

  function _handleUserListDelete(): void {
    if (!readCampaign?.users?.length) return;
    // TODO: move to redux saga
    deleteAllUsersCampaignRequest(match?.params?.id)
      .then(res => {
        if (res.data.success) {
          RSSCDisplay?.(AlertSeverity.Success, "Les utilisateurs de cette campagne ont été supprimé avec succès !");
          deleteUserListCampaignAction(match?.params?.id);
        }
        else RSSCDisplay?.(AlertSeverity.Error, res.data.message);
      })
      .catch(e => {
        RSSCDisplay?.(AlertSeverity.Error, e);
      })
      .finally(() => {
        setShowDeleteUserListModal(false);
      });
    // deleteAllUsersAction(match?.params?.id);
  }


  /**
   * Handle user submit
   * @private
   */
  function _handleSubmit(): void {
    // setSubmitted(true);
    // @ts-ignore
    updateCampaignAction({ ...formValues, campaignId: match?.params?.id }); // TODO: update page when update user
  }


  /**
   * Handle new user submit
   * @param user
   */
  function handleNewUserSubmit(user: IUserFormValues): void {
    createUserAction({ ...user, campaignId: match?.params?.id });
  }


  /**
   * Handle direct call submit
   * @param data
   */
  function handleDirectCallSubmit(data: { phoneNumber: string, externalId: string }): void {
    campaignDirectCallRequest(data, match?.params?.id)
      .then(res => {
        if (res.data.success) RSSCDisplay?.(AlertSeverity.Success, "L'appel est en cours de lancement");
        else RSSCDisplay?.(AlertSeverity.Error, res.data.message);
      })
      .catch(e => {
        RSSCDisplay?.(AlertSeverity.Error, e);
      });
  }


  return (
    <>
      <AppBar goBack={true}/>
      <Container component={"main"} maxWidth="md" className={classes.container}>
        {
          loading
            ? <Loader classes={classes}/>
            : (
              <>
                <Paper className={cx(classes.paper, classes.campaignStatusContainer)}>
                  <Chip color="primary" icon={campaignStatusIcon} label={campaignStatusLabel}/>
                  {
                    isUnstartedCampaign &&
                  <Typography>
                    Souhaitez-vous démarrer la campagne ?&nbsp;&nbsp;
                    <Button variant={"contained"} size="small" onClick={_handleStart}>Oui</Button>
                  </Typography>
                  }
                </Paper>
                <Paper className={classes.paper}>
                  <CampaignDetailsForm formValues={formValues} updateFormHandler={handleUpdateFormValues}/>
                </Paper>
                <Paper className={classes.paper}>
                  <UserForm
                    formValues={newUserFormValues}
                    onChange={handleUserFormUpdate}
                    onSubmit={handleNewUserSubmit}
                  />
                </Paper>
                <Paper className={classes.paper}>
                  <UploadUserFileForm isFileWrongType={isFileWrongType} selectedFile={formValues?.clientDataFile} updateFormHandler={handleUpdateFormValues}/>
                </Paper>
                <Paper className={classes.paper}>
                  <CampaignStatsChart rawData={readCampaign?.stats}/>
                </Paper>
                <Paper className={classes.paper}>
                  <Typography variant="h6" gutterBottom style={{ marginBottom: "24px" }}>
                  Listes des clients
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <UserDataTable users={readCampaign?.users} onDelete={handleUserDelete}/>
                    </Grid>
                    <Grid item xs={12} style={{ display: "flex", justifyContent: "end" }}>
                      <Button
                        variant="contained"
                        color={"primary"}
                        disabled={!readCampaign?.users?.length}
                        onClick={() => setShowDeleteUserListModal(true)}
                      >
                      Supprimer tous les utilisateurs
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
                <Paper className={classes.paper}>
                  <DirectCallForm onSubmit={handleDirectCallSubmit}/>
                </Paper>
                {
                  !readCampaign?.started &&
                <Paper className={cx(classes.paper, classes.deleteContainer)}>
                  <Button variant={"contained"} size="small" color={"primary"} onClick={() => setShowDeleteModal(true)}>Supprimer la campagne</Button>
                </Paper>
                }
                <Fab variant="extended" className={classes.savingButton} color={"primary"} disabled={!savingAuthorized} onClick={_handleSubmit}>
                  <SaveIcon className={classes.extendedIcon}/>
                Sauvegarder
                </Fab>
                <Copyright/>
              </>
            )
        }
      </Container>
      <DeleteUserModal open={showDeleteUserModal} onClose={() => setShowDeleteUserModal(false)} onPositiveAction={_handleUserDelete}/>
      <DeleteAllUsersModal open={showDeleteUserListModal} onClose={() => setShowDeleteUserListModal(false)} onPositiveAction={_handleUserListDelete}/>
      <DeleteCampaignModal open={showDeleteModal} onClose={() => setShowDeleteModal(false)} onPositiveAction={handleDelete}/>
      <StartCampaignModal open={showStartCampaignModal} onClose={() => setShowStartCampaignModal(false)} onPositiveAction={handleStart}/>
    </>
  );
};

const mapStateToProps = state => ( {
  readCampaign:                     state?.campaign?.readCampaign,
  createUserRequestStatusState:     state?.user?.createUserRequestStatus,
  deleteCampaignRequestStatusState: state?.campaign?.deleteCampaignRequestStatus,
  deleteCampaignErrorMessageState:  state?.campaign?.deleteCampaignErrorMessage,
  fetchCampaignRequestStatusState:  state?.campaign?.fetchCampaignRequestStatus,
} );

const mapDispatchToProps = dispatch => ( {
  createUserAction:     bindActionCreators(createUserRoutine, dispatch),
  deleteUserAction:     bindActionCreators(deleteUserRoutine, dispatch),
  getCampaignAction:    bindActionCreators(getCampaignRoutine, dispatch),
  deleteCampaignAction: bindActionCreators(deleteCampaignRoutine, dispatch),
  deleteUserListCampaignAction: bindActionCreators(resetUserListReadCampaignState, dispatch),
  updateCampaignAction: bindActionCreators(updateCampaignRoutine, dispatch),
  startCampaignAction:  bindActionCreators(startCampaignRoutine, dispatch),
} );

export default connect(mapStateToProps, mapDispatchToProps)(EditCampaignContainer);
