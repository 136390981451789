// noinspection JSDeprecatedSymbols
import { CaseReducers }         from "@reduxjs/toolkit";
import { CampaignInitialState } from ".";
import { getCampaignsRoutine }  from "../../actions/campaign.actions";
import { REQUEST_STATUS }       from "../../constants/requestStatus";
import { ICampaignModel }       from "../../models/ICampaignModel";


export interface IGetCampaignsInitialState {
  currentCampaigns: Array<ICampaignModel> | null,
  completedCampaigns: Array<ICampaignModel> | null,
  fetchCampaignsErrorMessage: string | null;
  fetchCampaignsRequestStatus: REQUEST_STATUS;
  unStartedCampaigns: Array<ICampaignModel> | null,
}


export const GET_CAMPAIGNS_INITIAL_STATE: IGetCampaignsInitialState = {
  currentCampaigns:            [],
  completedCampaigns:          [],
  fetchCampaignsErrorMessage:  null,
  fetchCampaignsRequestStatus: REQUEST_STATUS.INITIAL,
  unStartedCampaigns:          [],
};


// noinspection JSDeprecatedSymbols
export const getCampaignsPartReducer: CaseReducers<CampaignInitialState, any> = {
  [getCampaignsRoutine.REQUEST]: state => {
    state.fetchCampaignsRequestStatus = REQUEST_STATUS.FETCHING;
  },
  [getCampaignsRoutine.SUCCESS]: (state, action) => {
    try {
      state.fetchCampaignsRequestStatus = REQUEST_STATUS.FETCHED;
      // TODO: remove to pre-filter request
      state.currentCampaigns            = [];
      state.completedCampaigns          = [];
      state.unStartedCampaigns          = [];
      for (const campaign of action?.payload?.campaigns) {
        campaign.id = campaign._id;
        if (campaign?.started) {
          // @ts-ignore
          state.currentCampaigns.push(campaign);
        }
        else {
          // @ts-ignore
          state.unStartedCampaigns.push(campaign);
        }
      }
    }
    catch (e) {
      state.fetchCampaignsRequestStatus = REQUEST_STATUS.FAILED;
      state.fetchCampaignsErrorMessage  = e.message;
    }
  },
  [getCampaignsRoutine.FAILURE]: (state, action) => {
    state.fetchCampaignsRequestStatus = REQUEST_STATUS.FAILED;
    state.fetchCampaignsErrorMessage  = action?.payload?.message;
  },
};
