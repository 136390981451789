import React, { useContext, useEffect, useMemo, useState } from "react";
import { bindActionCreators }                              from "redux";
import { shuffle }                                         from "lodash";
import cx                                                  from "classnames";
import { connect }                                         from "react-redux";
import Avatar                                              from "@material-ui/core/Avatar";
import Button                                              from "@material-ui/core/Button";
import CssBaseline                                         from "@material-ui/core/CssBaseline";
import FormControlLabel                                    from "@material-ui/core/FormControlLabel";
import Checkbox                                            from "@material-ui/core/Checkbox";
import Box                                                 from "@material-ui/core/Box";
import LockOutlinedIcon                                    from "@material-ui/icons/LockOutlined";
import Typography                                          from "@material-ui/core/Typography";
import Container                                           from "@material-ui/core/Container";
import { ButtonGroup, ImageList, ImageListItem, TextField }  from "@material-ui/core";
import { useStyles }                                       from "./Login.stylemaker";
import Copyright                                           from "../../Commons/Copyright/Copyright";
import { ReactComponent as MousquetairesLogo }             from "../../../assets/img/logo.svg";
import { loginRoutine }                                    from "../../../actions/app.actions";
import { useMount }                                        from "../../../hooks/useMount";
import { StorageProperties }                               from "../../../constants/storageProperties";
import { REQUEST_STATUS }                                  from "../../../constants/requestStatus";
import { RequestStatusSnackbarContext }                    from "../../../contexts/RequestStatusSnackbarContext";
import { AlertSeverity }                                   from "../../../constants/alertSeverity";


interface LoginInterface {
  loginAction: any
  loginErrorMessage: string,
  loginStatus: REQUEST_STATUS,
}


const Login: React.FC<LoginInterface> = ({ loginAction, loginErrorMessage, loginStatus }) => {
  const classes                                 = useStyles();
  const [password, setPassword]                 = useState("");
  const [rememberPassword, setRememberPassword] = useState(false);
  const memoizedShuffleNumbers: Array<number>   = useMemo(() => shuffle([1, 2, 3, 4, 5, 6, 7, 8, 9, 0]), []);
  const { display: RSSCDisplay }                = useContext(RequestStatusSnackbarContext);
  const inputIsDisabled: boolean                = password.length >= 8;


  useMount(() => {
    if (localStorage.getItem(StorageProperties.RememberPassword) === "true") {
      setRememberPassword(true);
      if (localStorage.getItem(StorageProperties.RefreshToken)) {
        setPassword("00000000");
      }
    }
  });

  /**
   * On bad connection
   */
  useEffect(() => {
    if (loginStatus === REQUEST_STATUS.FAILED) {
      RSSCDisplay?.(AlertSeverity.Error, loginErrorMessage);
    }
  }, [loginStatus]);


  /**
   * Set the password
   * @param input
   * @private
   */
  function _setPassword(input: number): void {
    if (password.length < 8) {
      setPassword(`${password}${input}`);
    }
  }


  /**
   * Erase one char of the password from the end
   * @private
   */
  function _eraseOneChar() {
    if (password.length) {
      setPassword(password.substring(0, password.length - 1));
    }
  }


  /**
   * Reset password
   * @private
   */
  function _resetPassword() {
    setPassword("");
  }


  /**
   * User wants or not to remember his password
   * @param {event} e
   * @private
   */
  function _rememberPassword(e) {
    const isChecked = e?.target?.checked;
    setRememberPassword(isChecked);
    localStorage.setItem(StorageProperties.RememberPassword, isChecked);
  }


  /**
   * Handle submit event
   * @private
   */
  function _handleSubmit(e) {
    e.preventDefault();
    if (password) {
      loginAction({ password, rememberPassword });
    }
  }


  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline/>
      <div className={classes.paper}>
        <Typography component="h1" variant="h1" className={classes.title}>
          <MousquetairesLogo/>
          Les Campagnes Mousquetaires
        </Typography>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon/>
        </Avatar>
        <Typography component="h2" variant="h5">
          Connexion
        </Typography>
        <form className={classes.form} noValidate onSubmit={_handleSubmit}>
          <TextField
            className={cx(classes.input, { [classes.fullFilledInput]: password })}
            disabled
            id="outlined-password-input"
            label={password ? "" : "e.g. 42819935"}
            type={"password"}
            variant="outlined"
            value={password}
          />
          <ButtonGroup size="small" className={classes.actionButtonGroup}>
            <Button aria-label="Effacer" onClick={_eraseOneChar}>Effacer</Button>
            <Button aria-label="Réinitialiser" onClick={_resetPassword}>Réinitialiser</Button>
          </ButtonGroup>
          <ImageList rowHeight={160} cols={3} className={classes.gridList}>
            {
              memoizedShuffleNumbers.map(number => (
                <ImageListItem key={number} cols={1} className={classes.numberTile}>
                  <Button variant="contained" disabled={inputIsDisabled} onClick={() => _setPassword(number)}>
                    <Typography component="h1" variant="h5">
                      {number}
                    </Typography>
                  </Button>
                </ImageListItem>
              ))
            }
          </ImageList>
          <Button
            className={classes.submit}
            color="primary"
            disabled={password.length !== 8}
            type="submit"
            variant="contained"
          >
            Se connecter
          </Button>
          <FormControlLabel
            control={
              <Checkbox checked={rememberPassword} color="primary" onChange={_rememberPassword}/>
            }
            label="Se souvenir de moi"
          />
        </form>
      </div>
      <Box mt={8}>
        <Copyright/>
      </Box>
    </Container>
  );
};

const mapStateToProps    = state => ( {
  loginErrorMessage: state.app.loginErrorMessage,
  loginStatus:       state.app.loginStatus,
} );
const mapDispatchToProps = dispatch => ( {
  loginAction: bindActionCreators(loginRoutine.trigger, dispatch),
} );

export default connect(mapStateToProps, mapDispatchToProps)(Login);
