// noinspection JSDeprecatedSymbols
import { CaseReducers }          from "@reduxjs/toolkit";
import { CampaignInitialState }  from "./index";
import { createCampaignRoutine } from "../../actions/campaign.actions";
import { REQUEST_STATUS }        from "../../constants/requestStatus";


export interface ICreateCampaignInitialState {
  createCampaignErrorMessage: string | null;
  createCampaignStatus: REQUEST_STATUS;
}


export const CREATE_CAMPAIGN_INITIAL_STATE: ICreateCampaignInitialState = {
  createCampaignErrorMessage: null,
  createCampaignStatus:       REQUEST_STATUS.INITIAL,
};

// noinspection JSDeprecatedSymbols
export const createCampaignPartReducer: CaseReducers<CampaignInitialState, any> = {
  [createCampaignRoutine.REQUEST]: state => {
    state.createCampaignStatus = REQUEST_STATUS.FETCHING;
  },
  [createCampaignRoutine.SUCCESS]: state => {
    try {
      state.createCampaignStatus       = REQUEST_STATUS.FETCHED;
      state.createCampaignErrorMessage = CREATE_CAMPAIGN_INITIAL_STATE.createCampaignErrorMessage;
    }
    catch (e) {
      state.createCampaignStatus       = REQUEST_STATUS.FAILED;
      state.createCampaignErrorMessage = e.message;
    }
  },
  [createCampaignRoutine.FAILURE]: (state, action) => {
    state.createCampaignStatus       = REQUEST_STATUS.FAILED;
    state.createCampaignErrorMessage = action?.payload?.message;
  },
  [createCampaignRoutine.FULFILL]: state => {
    state.createCampaignStatus = REQUEST_STATUS.INITIAL;
  },
};
