import React                                      from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { RoutePath }                              from "../../constants/routePath";
import Dashboard                                  from "../Views/Dashboard/Dashboard";
import Login                                      from "../Views/Login/Login";
import PrivateRoute                               from "./PrivateRoute";
import Page404                                    from "./Page404";
import { useSelector }                            from "react-redux";
import CreateCampaignContainer                    from "../Views/CreateCampaign/CreateCampaignContainer";
import EditCampaignContainer                      from "../Views/EditCampaign/EditCampaignContainer";


const Router: React.FC = () => {

  // @ts-ignore
  const authed = useSelector(state => state?.app?.authed);

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={[RoutePath.Root, RoutePath.Login]}>
          {
            authed
            ? <Redirect to={RoutePath.Dashboard}/>
            : <Login/>
          }
        </Route>
        <PrivateRoute component={Dashboard} authed={authed} path={RoutePath.Dashboard}/>
        <PrivateRoute component={CreateCampaignContainer} authed={authed} path={RoutePath.CreateCampaign}/>
        {/*// @ts-ignore */}
        <PrivateRoute component={EditCampaignContainer} authed={authed} path={RoutePath.EditCampaign}/>
        <Route path={RoutePath.All}>
          <Page404/>
        </Route>
      </Switch>
    </BrowserRouter>
  );
};


export default Router;
