import axiosInstance from "./axiosInstance";
import crypto        from "crypto";


function encrypt(text) {
  return crypto.createHash("sha256").update(text).digest("base64");
}


export function authRequest(password: string): Promise<unknown> {
  const timestamp       = Date.now();
  const minuteRest      = timestamp % ( 1000 * 60 );
  const previousMinute  = timestamp - minuteRest;
  const cryptedPassword = encrypt(String(password) + previousMinute);
  return axiosInstance.post("/auth", {
    password: cryptedPassword,
  });
}


export function refreshTokenRequest(refreshToken: string): Promise<unknown> {
  return axiosInstance.post("/auth/refresh", {
    refreshToken,
  });
}
