import * as React                                                                      from "react";
import { useStyles }                                                                   from "../DataTable.stylemaker";
import { useMount }                                                                    from "../../../../hooks/useMount";
import { connect, useDispatch }                                                        from "react-redux";
import { deleteCampaignRoutine, getCampaignsRoutine }                                  from "../../../../actions/campaign.actions";
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, Tooltip } from "@material-ui/core";
import { StyledTableCell }                                                             from "../../../Commons/TableCell/TableCell";
import { REQUEST_STATUS }                                                              from "../../../../constants/requestStatus";
import TableNoContent                                                                  from "../../../Commons/TableNoContent/TableNoContent";
import TablePagination                                                                 from "../../../Commons/TablePagination/TablePagination";
import { ICampaignModel }                                                              from "../../../../models/ICampaignModel";
import Copyright                                                                       from "../../../Commons/Copyright/Copyright";
import TableRow                                                                        from "../../../Commons/TableRow/TableRow";
import FindInPageIcon                                                                  from "@material-ui/icons/FindInPage";
import { RoutePath }                                                                   from "../../../../constants/routePath";
import { bindActionCreators }                                                          from "redux";
import { useHistory }                                                                  from "react-router-dom";


interface CurrentDataTableInterface {
  deleteCampaignAction: (id: string | number) => void,
  fetchCampaignStatusState: REQUEST_STATUS,
  fetchErrorMessage: string | null,
  currentCampaignsState: Array<ICampaignModel>
}


const CurrentDataTable: React.FC<CurrentDataTableInterface> = ({
                                                                 deleteCampaignAction,
                                                                 fetchCampaignStatusState,
                                                                 fetchErrorMessage,
                                                                 currentCampaignsState,
                                                               }) => {

  const classes            = useStyles();
  const history            = useHistory();
  const dispatch           = useDispatch();
  const isCurrentCampaigns = fetchCampaignStatusState === REQUEST_STATUS.FETCHED && !!currentCampaignsState.length;

  useMount(() => {
    dispatch(getCampaignsRoutine.trigger());
  });


  /**
   * Handle use case: user wants edit campaign
   * @param event
   * @param rowValue
   * @private
   */
  function _handleEditCampaignClick(event?: unknown, rowValue?: ICampaignModel) {
    if (rowValue?.id) history.push(RoutePath.EditCampaign.replace(":id", `${rowValue.id}`));
  }


  return (
    <>
      <div className={classes.container}>
        <TableContainer>
          <Table size="small" aria-label="unstarted campaign table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Enseigne</StyledTableCell>
                <StyledTableCell align="center">Produit</StyledTableCell>
                <StyledTableCell align="center">Marque</StyledTableCell>
                <StyledTableCell align="center"/>
              </TableRow>
            </TableHead>
            {
              isCurrentCampaigns &&
              <TableBody>
                {
                  currentCampaignsState.map((row) => (
                    <TableRow key={row.externalId} onClick={_handleEditCampaignClick} rowValue={row} className={classes.row}>
                      <TableCell component="th" scope="row">
                        {row.enseigneLabel}
                      </TableCell>
                      <TableCell align="center">{row.productLabel}</TableCell>
                      <TableCell align="center">{row.productBrand}</TableCell>
                      <TableCell align="center">
                        <Tooltip title="Voir les détails de la campagne">
                          <IconButton aria-label="show campaign details" size="small" color={"primary"}>
                            <FindInPageIcon fontSize="inherit"/>
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            }
          </Table>
        </TableContainer>
        <TableNoContent requestStatus={fetchCampaignStatusState} message={!isCurrentCampaigns ? "Pas de campagnes en cours" : fetchErrorMessage}/>
        <TablePagination rows={currentCampaignsState}/>
      </div>
      <Copyright/>
    </>
  );
};

CurrentDataTable.defaultProps = {
  currentCampaignsState: [],
};

const mapStateToProps = state => ( {
  fetchCampaignStatusState: state?.campaign?.fetchCampaignsRequestStatus,
  fetchErrorMessage:        state?.campaign?.fetchCampaignsErrorMessage,
  currentCampaignsState:    state?.campaign?.currentCampaigns,
} );


const mapDispatchToProps = dispatch => ( {
  deleteCampaignAction: bindActionCreators(deleteCampaignRoutine, dispatch),
} );

export default connect(mapStateToProps, mapDispatchToProps)(CurrentDataTable);
