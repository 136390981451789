import React, { useEffect, useState } from "react";
import { ICampaignStatsModel }        from "../../../models/ICampaignStatsModel";
import Typography                     from "@material-ui/core/Typography";
import StatCard                       from "../../Commons/StatCard/StatCard";
import { Button, Grid }                       from "@material-ui/core";
import { useDispatch } from "react-redux";
import SyncIcon from "@material-ui/icons/Sync";
import { getCampaignRoutine } from "../../../actions/campaign.actions";
import { useParams } from "react-router-dom";
interface IBarChartData {
  label: string,
  value: number,
}


interface ICampaignStatsChartProps {
  rawData: ICampaignStatsModel,
}


const CampaignStatsChart = ({ rawData }) => {

  const [data, setData]             = useState<IBarChartData[]>([]);
  const [noDataFlag, setNoDataFlag] = useState<boolean>(true);
  const [maxYAxis, setMaxYAxis]     = useState<number>(0); 

  const dispatch = useDispatch();

  const {id} = useParams<{id: string}>();

  useEffect(() => {
    if (rawData?.length) return;
    const newData: Array<IBarChartData> = [];
    const entries: [string, number][]   = Object.entries(rawData);
    for (const [key, value] of entries) {
      switch (key) {
          case "nbUser":
            newData.push({ label: "Clients", value });
            break;
          case "nbUserCalled":
            newData.push({ label: "Clients contactés", value });
            break;
          case "nbUserCompleted":
            newData.push({ label: "Clients appelés terminé", value });
            break;
          case "nbUserNotCalledBack":
            newData.push({ label: "Clients non rappelés", value });
            break;
          case "nbUserDontCalledBack":
            newData.push({ label: "Clients à ne pas rappeler", value });
            break;
          case "nbUserHangup":
            newData.push({ label: "Clients à avoir raccroché", value });
            break;
          case "nbUserNeverAnswered":
            newData.push({ label: "Clients à n'avoir jamais répondu", value });
            break;
          case "nbUserFail":
            newData.push({ label: "Clients injoignables", value });
            break;
          case "nbUserUnassignedPhone":
            newData.push({ label: "Clients avec un numéro non assigné", value });
            break;
          case "nbUserCallScheduled":
            newData.push({ label: "Clients avec un appel programmé", value });
            break;
          case "nbUserCallInProgress":
            newData.push({ label: "Clients avec un appel en cours", value });
            break;
          case "nbUserAnswered":
            newData.push({ label: "Clients ayant répondu", value });
            break;
          case "nbCalls":
            newData.push({ label: "Appels", value });
            break;
          case "nbCallsAnsweringMachine":
            newData.push({ label: "Appels où une machine a répondu", value });
            break;
          case "nbCallAnswered":
            newData.push({ label: "Appels répondus", value });
            break;
          case "nbCallFail":
            newData.push({ label: "Appels en échec", value });
            break;
          case "nbUserMessageGiven":
            newData.push({ label: "Clients à avoir reçu un message", value });
            break;
          case "nbUserSmsSended":
            newData.push({ label: "Clients à avoir reçu un SMS", value });
            break;
          case "nbUserFlowCompleted":
            newData.push({ label: "Clients à avoir terminé l'expérience", value });
            break;
          case "nbUserToRecontact":
            newData.push({ label: "Clients à recontacter", value });
            break;
      }
      setData(newData);
      if (value) setNoDataFlag(false);
      if (value > maxYAxis) setMaxYAxis(value);
    }
  }, [rawData]);

  const refreshCampaign = () => {
    dispatch(getCampaignRoutine.trigger(id));
  };

  return (
    <>
      <Typography variant="h6" style={{ marginBottom: "1.4em" }}>
        Statistiques de la campagne
        <Button
          variant="contained"
          color="primary"
          endIcon={<SyncIcon/>}
          style={{marginLeft: "20px"}}
          onClick={refreshCampaign}
        >
        Refresh
        </Button>
      </Typography>
      <div>
        {
          noDataFlag
            ? (
              <Typography align={"center"} color={"primary"} variant="overline" display="block" gutterBottom>
              Pas de données
              </Typography>
            )
            : (
              <Grid container spacing={3}>
                {
                  data.map(({label, value}) => (
                    <Grid key={label} item sm={4} xs={12}>
                      <StatCard title={label} value={value}/>
                    </Grid>
                  ))
                }
              </Grid>
            )
        }
      </div>
    </>
  );
};

CampaignStatsChart.defaultProps = {
  rawData: [],
};

export default CampaignStatsChart;
