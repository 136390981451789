import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  root:           {
    height: "100%",
  },
  content:        {
    height:         "100%",
    display:        "flex",
    flexDirection:  "column",
    justifyContent: "space-between",
    paddingBottom:  16,
  },
  titleContainer: {
    fontSize:      "0.89rem",
    fontWeight:    400,
    textTransform: "uppercase",
  },
  valueContainer: {
    fontSize: "3rem",
  },
});
