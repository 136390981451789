import { createReducer }        from "@reduxjs/toolkit";
import { REQUEST_STATUS }       from "../../constants/requestStatus";
import { loginRoutine, logout } from "../../actions/app.actions";
import { StorageProperties }    from "../../constants/storageProperties";


interface AppInitialState {
  authed: boolean;
  loginErrorMessage: string | null;
  loginStatus: REQUEST_STATUS | null;
  refreshToken: string | null;
  token: string | null;
}


export const INITIAL_STATE: AppInitialState = {
  authed:            false,
  loginErrorMessage: null,
  loginStatus:       REQUEST_STATUS.INITIAL,
  refreshToken:      null,
  token:             null,
};


const appReducer = createReducer(INITIAL_STATE, {
  [loginRoutine.REQUEST]: state => {
    state.loginStatus = REQUEST_STATUS.FETCHING;
  },
  [loginRoutine.SUCCESS]: (state, action) => {
    try {
      state.loginStatus  = REQUEST_STATUS.FETCHED;
      state.token        = action.payload.token;
      state.refreshToken = action.payload.refreshToken;
      state.authed       = true;

      if (action.payload.rememberPassword) {
        localStorage.setItem(StorageProperties.RefreshToken, action.payload.refreshToken);
      }
    }
    catch (e) {
      state                   = INITIAL_STATE;
      state.loginStatus       = REQUEST_STATUS.FAILED;
      state.loginErrorMessage = e.message;
      localStorage.removeItem("refresh_token");
    }
  },
  [loginRoutine.FAILURE]: (state, action) => {
    state                   = INITIAL_STATE;
    state.loginStatus       = REQUEST_STATUS.FAILED;
    state.loginErrorMessage = action?.payload?.message;
    localStorage.removeItem(StorageProperties.RefreshToken);
  },
  [logout.type]: state => {
    for (const key in StorageProperties) localStorage.removeItem(StorageProperties[key]);
    return INITIAL_STATE;
  },
});

// @ts-ignore
export default appReducer;
