import React                                     from "react";
import Typography                                from "@material-ui/core/Typography";
import Grid                                      from "@material-ui/core/Grid";
import { Button, FormHelperText, Link, Tooltip } from "@material-ui/core";
import BackupIcon                                from "@material-ui/icons/Backup";
import HelpOutlineIcon                           from "@material-ui/icons/HelpOutline";
import { useStyles }                             from "./UploadUserFileForm.stylemaker";


/**
 * Form default values Interface
 */
export interface IUploadUserFileFormValues {
  clientDataFile?: File | null,
}


/**
 * Form default value(s)
 */
export const UploadUserFileFormDefaultValues = {
  clientDataFile: null,
};


/**
 * UploadUserFileForm Props Interface
 */
interface IUploadUserFileFormProps {
  isFileWrongType: boolean,
  selectedFile: File | null | undefined,
  updateFormHandler: (name: string, value: string | number | null) => void,
}


/**
 * UploadUserFileForm Functional Component
 * @param isFileWrongType
 * @param selectedFile
 * @param updateFormHandler
 * @constructor
 */
const UploadUserFileForm: React.FC<IUploadUserFileFormProps> = ({ isFileWrongType = false, selectedFile, updateFormHandler }) => {
  const classes               = useStyles();
  const isSelectedFileCorrect = selectedFile && !isFileWrongType;


  /**
   * Handle user input file
   * @param event
   * @private
   */
  function _handleFile(event) {
    const file = event?.target?.files[0];
    if (!file) {
      console.error("Internal issue: no file added");
      updateFormHandler(event?.target?.name, null);
      return;
    }
    updateFormHandler(event?.target?.name, file);
  }


  /**
   * Handle user want to delete file
   * @private
   */
  function _deleteFile() {
    updateFormHandler("clientDataFile", null);
  }


  return (
    <>
      <Typography variant="h6" gutterBottom>
        Clients à contacter
      </Typography>
      <Grid container spacing={3} className={classes.formContainer}>
        <Grid item xs={12} className={classes.uploadBtnContainer}>
          <Button
            variant="contained"
            component="label"
          >
            <BackupIcon color={"primary"} className={classes.uploadIcon}/>
            Choisir un fichier
            <input
              id={"clientDataFile"}
              name={"clientDataFile"}
              type="file"
              hidden
              onChange={_handleFile}
            />
          </Button>
          <Typography variant={"body2"} color={isSelectedFileCorrect ? "textPrimary" : "textSecondary"}>
            {
              isSelectedFileCorrect &&
              selectedFile?.name
            }
            {
              isFileWrongType &&
              "Le fichier sélectionné n'est pas du bon format."
            }
            {
              !selectedFile && !isFileWrongType &&
              "Pas de fichier sélectionné"
            }
            {
              ( isSelectedFileCorrect || isFileWrongType ) &&
              <>
                &nbsp;<Link onClick={_deleteFile}>Supprimer le fichier</Link>
              </>
            }
          </Typography>
        </Grid>
        <FormHelperText className={classes.formHelper}>
          <i>Fichier au format CSV uniquement</i>
          <Tooltip title="Deux colonnes doivent être présentes dans votre fichier CSV: id (identifiant du client) et phoneNumber (numéro de téléphone du client). Chaque valeur doit être séparée par un point-virgule.">
            <HelpOutlineIcon/>
          </Tooltip>
        </FormHelperText>
      </Grid>
    </>
  );
};
export default UploadUserFileForm;
