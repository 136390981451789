import uniqid                                                                                                                               from "uniqid";
import { all, call, fork, put, takeLatest }                                                                                                                       from "redux-saga/effects";
import {
  createCampaignRoutine,
  deleteCampaignRoutine,
  getCampaignRoutine,
  getCampaignsRoutine,
  resetCampaignState,
  startCampaignRoutine,
  updateCampaignRoutine,
  updateCampaignStartedState,
} from "../actions/campaign.actions";
import { deleteCampaignRequest, getCampaignRequest, getCampaignsRequest, patchCampaignRequest, putCampaignRequest, startCampaignRequest }                         from "../requests/campaignRequests";
import { createUsersRoutine, updateUsersRoutine }                                                                                                                 from "../actions/user.actions";


/**
 ******************************************************************************************************************* \
 * CAMPAIGN/CREATE/ONE
 */

function* createCampaign({ payload }) {
  try {
    yield put(createCampaignRoutine.request());
    const data       = {
      externalId:          uniqid(),
      enseigneLabel:       payload.enseigneName,
      enseigneUrl:         payload.enseigneURL,
      productLabel:        payload.productLabel,
      productBrand:        payload.productBrand,
      riskLabel:           payload.riskLabel,
      // productBatchNumbers: payload.productBatchNumbers?.filter(Boolean),
      started:             payload.instantStart,
    };
    const res        = yield call(putCampaignRequest, data);
    const campaignId = res?.data?.result?.campaign?._id;
    if (payload?.clientDataFile && campaignId) {
      yield put(createUsersRoutine({ campaignId, clientDataFile: payload?.clientDataFile }));
    }
    yield put(createCampaignRoutine.success());
  }
  catch (e) {
    console.error(e);
    yield put(createCampaignRoutine.failure({ message: e.message }));
  }
  yield put(createCampaignRoutine.fulfill());
}


function* watchCreateCampaign() {
  // @ts-ignore
  yield takeLatest(createCampaignRoutine.TRIGGER, createCampaign);
}


/**
 ******************************************************************************************************************* \
 * CAMPAIGN/PATCH/ONE
 */

function* updateCampaign({ payload }) {
  try {
    yield put(updateCampaignRoutine.request());

    const data       = {
      externalId:          payload?.externalId,
      enseigneLabel:       payload.enseigneName,
      enseigneUrl:         payload.enseigneURL,
      productLabel:        payload.productLabel,
      productBrand:        payload.productBrand,
      riskLabel:           payload.riskLabel,
      productBatchNumbers: payload.productBatchNumbers?.filter(Boolean),
      started:             payload.instantStart,
    };

    const res        = yield call(patchCampaignRequest, data, payload?.campaignId); // reduce call

    const campaignId = res?.data?.result?.campaign?._id;

    if (payload?.clientDataFile && campaignId) {
      yield put(updateUsersRoutine({ campaignId, clientDataFile: payload?.clientDataFile }));
    }

    yield put(updateCampaignRoutine.success({ campaign: res?.data?.result?.campaign }));
  }
  catch (e) {
    console.error(e);
    yield put(updateCampaignRoutine.failure({ message: e.message }));
  }
  yield put(updateCampaignRoutine.fulfill());
}


function* watchUpdateCampaign() {
  // @ts-ignore
  yield takeLatest(updateCampaignRoutine.TRIGGER, updateCampaign);
}


/**
 ******************************************************************************************************************* \
 * CAMPAIGN/DELETE/ONE
 */

function* deleteCampaign({ payload: campaignId }) {
  try {
    yield put(deleteCampaignRoutine.request());
    yield call(deleteCampaignRequest, campaignId);
    yield put(deleteCampaignRoutine.success());
  }
  catch (e) {
    console.error(e);
    yield put(deleteCampaignRoutine.failure({ message: e.message }));
  }
  yield put(deleteCampaignRoutine.fulfill());
}


function* watchDeleteCampaign() {
  // @ts-ignore
  yield takeLatest(deleteCampaignRoutine.TRIGGER, deleteCampaign);
}


/**
 ******************************************************************************************************************* \
 * CAMPAIGN/GET/ONE
 */

function* getCampaignById({ payload: campaignId }) {
  try {
    yield put(getCampaignRoutine.request());
    const res = yield call(getCampaignRequest, campaignId);
    yield put(getCampaignRoutine.success({ campaign: res?.data?.result }));
  }
  catch (e) {
    console.error(e);
    yield put(getCampaignRoutine.failure({ message: e.message }));
  }
  yield put(getCampaignRoutine.fulfill());
}


function* watchGetCampaignById() {
  // @ts-ignore
  yield takeLatest(getCampaignRoutine.TRIGGER, getCampaignById);
}


/**
 ******************************************************************************************************************* \
 * CAMPAIGN/START/ONE
 */

function* startCampaignById({ payload: campaignId }) {
  try {
    yield put(startCampaignRoutine.request());
    const res = yield call(startCampaignRequest, campaignId);
    // @ts-ignore
    yield put(updateCampaignStartedState({ campaignId: res?.data?.campaign?._id }));
    yield put(startCampaignRoutine.success());
  }
  catch (e) {
    console.error(e);
    yield put(startCampaignRoutine.failure({ message: e.message }));
  }
  yield put(startCampaignRoutine.fulfill());
}


function* watchStartCampaignById() {
  // @ts-ignore
  yield takeLatest(startCampaignRoutine.TRIGGER, startCampaignById);
}


/**
 ******************************************************************************************************************* \
 * CAMPAIGN/GET/ALL
 */

function* getCampaigns() {
  try {
    yield put(getCampaignsRoutine.request());
    const res = yield call(getCampaignsRequest);
    yield put(getCampaignsRoutine.success({ campaigns: res?.data?.result }));
    yield put(resetCampaignState());
  }
  catch (e) {
    console.error(e);
    yield put(getCampaignsRoutine.failure({ message: e.message }));
  }
  yield put(getCampaignsRoutine.fulfill());
}


function* watchGetCampaigns() {
  yield takeLatest(getCampaignsRoutine.TRIGGER, getCampaigns);
}


/**
 ******************************************************************************************************************* \
 * root
 */

export default function* root() {
  yield all([
    fork(watchGetCampaigns),
    fork(watchCreateCampaign),
    fork(watchGetCampaignById),
    fork(watchUpdateCampaign),
    fork(watchDeleteCampaign),
    fork(watchStartCampaignById),
  ]);
}
