import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ( {
  snackbarContainer: {
    "& .MuiSnackbarContent-message": {
      display:                       "flex",
      justifyContent:                "center",
      alignItems:                    "center",
      "& .MuiCircularProgress-root": {
        marginRight: theme.spacing(2),
      },
    },
  },
} ));
