import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ( {
  formContainer:      {
    marginTop: 12,
  },
  formHelper:         {
    marginTop: 5,
    display: "flex",
    alignItems: "center",
    "& > svg": {
      width: "0.7em",
      height: "0.7em",
      marginLeft: theme.spacing(0.3),
      cursor: "pointer",
    }
  },
} ));
