// noinspection JSDeprecatedSymbols
import { CaseReducers }          from "@reduxjs/toolkit";
import { CampaignInitialState }  from ".";
import { updateCampaignRoutine } from "../../actions/campaign.actions";
import { REQUEST_STATUS }        from "../../constants/requestStatus";
import { ICampaignModel }        from "../../models/ICampaignModel";
import { IUserModel }            from "../../models/IUserModel";


export interface IExtendedCampaignModel extends ICampaignModel { // request // TODO: model resquest
  users: IUserModel;
}


export interface IUpdateCampaignInitialState {
  patchCampaignErrorMessage: string | null;
  patchCampaignRequestStatus: REQUEST_STATUS;
}


export const UPDATE_CAMPAIGN_INITIAL_STATE: IUpdateCampaignInitialState = {
  patchCampaignErrorMessage:  null,
  patchCampaignRequestStatus: REQUEST_STATUS.INITIAL,
};


// noinspection JSDeprecatedSymbols
export const updateCampaignPartReducer: CaseReducers<CampaignInitialState, any> = {
  [updateCampaignRoutine.REQUEST]: state => {
    state.patchCampaignRequestStatus = REQUEST_STATUS.FETCHING;
  },
  [updateCampaignRoutine.SUCCESS]: (state, action) => {
    try {
      state.readCampaign               = Object.assign({}, action?.payload?.campaign, state.readCampaign); // TODO: find better relation for readCampaign reducer
      state.patchCampaignRequestStatus = REQUEST_STATUS.FETCHED;
    }
    catch (e) {
      state.patchCampaignRequestStatus = REQUEST_STATUS.FAILED;
      state.patchCampaignErrorMessage  = e.message;
    }
  },
  [updateCampaignRoutine.FAILURE]: (state, action) => {
    state.patchCampaignRequestStatus = REQUEST_STATUS.FAILED;
    state.patchCampaignErrorMessage  = action?.payload?.message;
  },
};
