// noinspection JSDeprecatedSymbols
import { CaseReducers }      from "@reduxjs/toolkit";
import { UserInitialState }  from "./index";
import { REQUEST_STATUS }    from "../../constants/requestStatus";
import { createUserRoutine } from "../../actions/user.actions";
import { remove }            from "lodash";


export interface ICreateUserInitialState {
  createUserErrorMessage: string | null;
  createUserRequestStatus: REQUEST_STATUS;
  enqueueCreatingUserRequests: Array<string>;
}


export const CREATE_USER_INITIAL_STATE: ICreateUserInitialState = {
  createUserErrorMessage:      null,
  createUserRequestStatus:     REQUEST_STATUS.INITIAL,
  enqueueCreatingUserRequests: [],
};

// noinspection JSDeprecatedSymbols
export const createUserPartReducer: CaseReducers<UserInitialState, any> = {
  [createUserRoutine.REQUEST]: (state, action) => {
    state.createUserRequestStatus = REQUEST_STATUS.FETCHING;
    state.enqueueCreatingUserRequests.push(action?.payload?.userExternalId);
  },
  [createUserRoutine.SUCCESS]: (state, action) => {
    state.createUserRequestStatus     = REQUEST_STATUS.FETCHED;
    state.enqueueCreatingUserRequests = remove(state.enqueueCreatingUsersRequests, action?.payload?.userExternalId);
  },
  [createUserRoutine.FAILURE]: (state, action) => {
    state.createUserRequestStatus     = REQUEST_STATUS.FAILED;
    state.createUserErrorMessage      = action?.payload?.message;
    state.enqueueCreatingUserRequests = remove(state.enqueueCreatingUsersRequests, action?.payload?.userExternalId);
  },
};
