import { AxiosPromise } from "axios";
import axiosInstance    from "./axiosInstance";


/**
 * Create users
 * @param data
 */
export function putUsersRequest(data: { campaign: string|number, users: Array<{id: string, phoneNumber: string}> }): AxiosPromise {
  return axiosInstance({
    url:    "/users",
    method: "PUT",
    data,
  });
}


/**
 * Create user
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function putUserRequest(data): AxiosPromise {
  return axiosInstance({
    url:    "/user",
    method: "PUT",
    data,
  });
}

/**
 * Create user
 */
export function deleteUserRequest(userId: string): AxiosPromise {
  return axiosInstance({
    url:    `/user/${userId}`,
    method: "DELETE",
  });
}
