import Typography from "@material-ui/core/Typography";
import Link       from "@material-ui/core/Link";
import React      from "react";


export default function Copyright(): JSX.Element {
  return (
    <div>
      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        HelloMyBot
        {" "}
        {new Date().getFullYear()}
      </Typography>
      <Typography variant="body2" align="center" style={{ color: "#abb9cf" }}>
        <Link color="inherit" href="https://hellomybot.io/?utm_source=LesMousquetaires">
          <svg xmlns="http://www.w3.org/2000/svg" width="8" height="13" viewBox="0 0 5 8.01" style={{ margin: "0 3px" }}>
            <path
              d="M4.978,3.084A.168.168,0,0,0,4.832,3h-2.2L3,.186A.169.169,0,0,0,2.7.077L.025,4.756a.168.168,0,0,0,.143.255H2.331L2.039,7.828a.169.169,0,0,0,.311.1L4.976,3.253a.168.168,0,0,0,0-.169Z"
              fill="#abb9cf"/>
          </svg>
          <i>by Hellomybot</i>
        </Link>
      </Typography>
    </div>
  );
}
