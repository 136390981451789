import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ( {
  loader:                  {
    position:       "fixed",
    top:            "calc(50vh - (40px/2))", // 40px is equal to the fixed size of svg icon
    display:        "flex",
    flexDirection:  "column",
    justifyContent: "center",
    alignItems:     "center",
  },
  loaderText:              {
    marginTop: theme.spacing(4),
  },
  container:               {
    marginTop:      theme.spacing(6),
    display:        "flex",
    flexDirection:  "column",
    justifyContent: "center",
    alignItems:     "center",
    marginBottom:   theme.spacing(8),
  },
  paper:                   {
    width:                                              "100%",
    marginBottom:                                       theme.spacing(6),
    padding:                                            theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding: theme.spacing(3),
    },
  },
  campaignStatusContainer: {
    display:        "flex",
    justifyContent: "space-between",
    alignItems:     "center",
  },
  deleteContainer:         {
    display:        "flex",
    justifyContent: "end",
  },
  savingButton:            {
    position: "fixed",
    bottom:   30,
  },
  extendedIcon:            {
    marginRight: theme.spacing(1),
  },
  disablePaper: {
    position: "relative",
    "&::after": {
      content: "'EN COURS DE DÉVELOPPEMENT'",
      borderRadius: 4,
      backgroundColor: "#000000a8",
      height: "100%",
      width: "100%",
      position: "absolute",
      top: 0,
      left: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "red",
      fontWeight: "bold",
      fontSize: 34,
    }
  }
} ));
