import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ( {
  paper:               {
    marginTop:     theme.spacing(8),
    display:       "flex",
    flexDirection: "column",
    alignItems:    "center",
  },
  actionButtonGroup:   {
    width:                              "205px !important",
    marginTop:                          7,
    "& > .MuiButton-outlinedSizeSmall": {
      padding: "3px 11px !important",
    },
  },
  avatar:              {
    margin:          theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form:                {
    width:             "100%", // Fix IE 11 issue.
    marginTop:         theme.spacing(1),
    display:           "flex",
    "flex-direction":  "column",
    "justify-content": "center",
    "align-items":     "center",
  },
  gridList:            {
    width:             "215px !important",
    "justify-content": "center",
    margin:            "20px 0px !important",
  },
  input:               {
    width:     "205px",
    marginTop: "20px !important",
  },
  fullFilledInput:     {
    "& input": {
      color: "black",
    },
  },
  numberTile:          {
    height:                      "70px !important",
    width:                       "70px !important",
    display:                     "flex",
    "justify-content":           "center",
    "align-items":               "center",
    "& > .MuiGridListTile-tile": {
      display:               "flex",
      "align-items":         "center",
      "& > .MuiButton-root": {
        padding: "14px 16px !important",
      },
    },
  },
  submit:              {
    width:              "205px !important",
    margin:             theme.spacing(3, 0, 2),
  },
  title:               {
    "margin-bottom":   "30px",
    "white-space":     "nowrap",
    "font-size":       "2rem",
    "display":         "flex",
    "justify-content": "center",
    "align-items":     "center",
    "& svg":           {
      width:       "55px",
      marginRight: "13px",
    },
  },
} ));
