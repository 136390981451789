import React, { PropsWithChildren } from "react";
import uniqId                       from "uniqid";
import { TableRow as MUITableRow }  from "@material-ui/core";


interface ITableRow {
  className?: string,
  onClick?: (event?: unknown, rowValue?: any) => void,
  rowValue?: unknown,
}


const TableRow: React.FC<PropsWithChildren<ITableRow>> = ({ children, className, onClick, rowValue }) => {

  /**
   * Provide event and row details
   * @param event
   * @private
   */
  function _handleClick(event) {
    onClick?.(event, rowValue);
  }

  return (
    <MUITableRow className={className} onClick={_handleClick}>
      {children}
    </MUITableRow>
  );
};

export default TableRow;
