import React               from "react";
import PropTypes           from "prop-types";
import { Redirect, Route } from "react-router-dom";
import { RoutePath }       from "../../constants/routePath";

interface PrivateRouteInterface {
  component: React.ComponentType,
  authed: boolean,
  path: string,
}

const PrivateRoute = ({ component: Component, authed, path }: PrivateRouteInterface): any => {

  /**
   *
   * @param props
   * @private
   */
  function _renderRoute(props) {
    return authed
      ? <Component {...props} />
      // eslint-disable-next-line react/prop-types
      : <Redirect to={{ pathname: RoutePath.Login, state: { from: props.location } }}/>;
  }

  return (
    <Route path={path} render={_renderRoute}/>
  );
};

PrivateRoute.propTypes = {

};

export default PrivateRoute;
