import { createReducer }                                                                from "@reduxjs/toolkit";
import { CREATE_USERS_INITIAL_STATE, createUsersPartReducer, ICreateUsersInitialState } from "./createUsers.reducer";
import { IUpdateUsersInitialState, UPDATE_USERS_INITIAL_STATE, updateUsersPartReducer } from "./updateUsersPart.reducer";
import { CREATE_USER_INITIAL_STATE, createUserPartReducer, ICreateUserInitialState } from "./createUser.reducer";
import { DELETE_USER_INITIAL_STATE, deleteUserPartReducer, IDeleteUserInitialState } from "./deleteUser.reducer";


export type UserInitialState = ICreateUsersInitialState & IUpdateUsersInitialState & ICreateUserInitialState & IDeleteUserInitialState;


export const INITIAL_STATE: UserInitialState = {
  ...CREATE_USERS_INITIAL_STATE,
  ...UPDATE_USERS_INITIAL_STATE,
  ...CREATE_USER_INITIAL_STATE,
  ...DELETE_USER_INITIAL_STATE,
};


const userReducer = createReducer(INITIAL_STATE, {
  ...createUsersPartReducer,
  ...updateUsersPartReducer,
  ...createUserPartReducer,
  ...deleteUserPartReducer,
});

// @ts-ignore
export default userReducer;
