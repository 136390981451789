import { loginRoutine }                     from "../actions/app.actions";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { authRequest, refreshTokenRequest } from "../requests/authRequest";
import { StorageProperties }                from "../constants/storageProperties";
import { addDefaultAuthorization }          from "../requests/axiosInstance";


function* automaticAuth() {
  // @ts-ignore
  const rememberPassword = yield call([localStorage, localStorage.getItem], StorageProperties.RememberPassword);
  // @ts-ignore
  const refreshToken     = yield call([localStorage, localStorage.getItem], StorageProperties.RefreshToken);
  if (rememberPassword && refreshToken) {
    try {
      yield put(loginRoutine.request({ refresh: true }));
      // @ts-ignore
      const res = yield call(refreshTokenRequest, [refreshToken]);
      yield put(loginRoutine.success({ ...res?.data?.result, refresh: true, rememberPassword }));
      addDefaultAuthorization(res?.data?.result?.token);
    }
    catch (e) {
      console.error(e);
      yield put(loginRoutine.failure({ message: e.message, refresh: true }));
    }
    yield put(loginRoutine.fulfill({ refresh: true }));
  }
}


function* login({ payload }) {
  try {
    yield put(loginRoutine.request());
    // @ts-ignore
    const res = yield call(authRequest, [payload?.password]);
    yield put(loginRoutine.success({ ...res?.data?.result, rememberPassword: payload?.rememberPassword }));
    addDefaultAuthorization(res?.data?.result?.token);
  }
  catch (e) {
    console.error(e);
    yield put(loginRoutine.failure({ message: e.message }));
  }
  yield put(loginRoutine.fulfill());
}


/**
 * Login watcher (take the last user request/action)
 */
function* watchLogin() {
  // @ts-ignore
  yield takeLatest(loginRoutine.TRIGGER, login);
}


export default function * root() {
  yield all([
    fork(watchLogin),
    automaticAuth(),
  ]);
}
