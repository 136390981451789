import { isNil }                     from "lodash";
import { ICreateCampaignFormValues } from "./CreateCampaignContainer";

export function checkCampaignDetailsForm(obj: ICreateCampaignFormValues): boolean {
  return (
    !!obj?.enseigneName?.replaceAll(/\s/g, "") &&
    !!obj?.enseigneURL?.replaceAll(/\s/g, "") &&
    !!obj?.productLabel?.replaceAll(/\s/g, "") &&
    !!obj?.productBrand?.replaceAll(/\s/g, "") &&
    !!obj?.riskLabel?.replaceAll(/\s/g, "")
  );
}

export function checkUserForm(obj: ICreateCampaignFormValues, isFileWrongType: boolean): boolean {
  return isNil(obj?.clientDataFile) || !isFileWrongType;
}
