import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ( {
  listItem:               {
    padding: theme.spacing(1, 0),
  },
  startContainer:         {
    marginTop: 20,
  },
  startLabelSubContainer: {
    display:              "flex",
    "& .MuiSvgIcon-root": {
      marginLeft: 6,
      "& > path": {
        fill: "#b7b7b7",
      },
    },
  },
} ));
