import React             from "react";
import { AlertSeverity } from "../constants/alertSeverity";


interface IRSCData {
  alertSeverity: AlertSeverity,
  alertText: string,
  autoHideDuration: number,
  open: boolean,
  setAlertSeverity?: (severity: AlertSeverity) => void,
  setAlertText?: (text: string) => void,
  setAutoHideDuration?: (time: number) => void,
  setOpen?: (flag: boolean) => void,
  display?: (severity: AlertSeverity, text: string, automaticHideDuration?: number) => void,
  reset?: () => void,
}


const defaultValue: IRSCData = {
  alertSeverity:    AlertSeverity.Info,
  alertText:        "",
  autoHideDuration: 6000,
  open:             false,
};

/**
 * Context
 */
export const RequestStatusSnackbarContext            = React.createContext(defaultValue);

/**
 * Provider
 * @param props
 * @constructor
 */
export const RequestStatusSnackbarProvider: React.FC = props => {
  const [alertSeverity, setAlertSeverity]       = React.useState<AlertSeverity>(defaultValue.alertSeverity);
  const [alertText, setAlertText]               = React.useState<string>(defaultValue.alertText);
  const [autoHideDuration, setAutoHideDuration] = React.useState<number>(defaultValue.autoHideDuration);
  const [open, setOpen]                         = React.useState<boolean>(defaultValue.open);


  /**
   * Handle state modifications
   * @param severity
   * @param text
   * @param automaticHideDuration
   */
  function display(severity = AlertSeverity.Info, text = "", automaticHideDuration?: number): void {
    setAlertSeverity(severity);
    setAlertText(text);
    setOpen(true);
    if (automaticHideDuration) setAutoHideDuration(automaticHideDuration);
  }


  /**
   * Handle reset state
   */
  function reset(): void {
    setAlertSeverity(AlertSeverity.Info);
    setAlertText("");
    setOpen(false);
  }


  const data = {
    alertSeverity,
    alertText,
    autoHideDuration,
    open,
    setAlertSeverity,
    setAlertText,
    setAutoHideDuration,
    setOpen,
    display,
    reset,
  };


  return (
    <RequestStatusSnackbarContext.Provider
      value={data}
    >
      {props.children}
    </RequestStatusSnackbarContext.Provider>
  );
};
