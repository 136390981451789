import React                                                                            from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";

interface IDeleteAllUsersModalProps {
  open: boolean,
  onClose: () => void,
  onPositiveAction: (event?: any) => void,
}

const DeleteAllUsersModal: React.FC<IDeleteAllUsersModalProps> = ({ open, onClose, onPositiveAction }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Êtes-vous bien sûr(e) de vouloir supprimer toute la liste des utilisateurs ?"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {
            "Une fois supprimée, il n'y a aucun moyen de récupérer la liste des utilisateurs."
          }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onPositiveAction} color="primary">
          Supprimer quand même
        </Button>
        <Button onClick={onClose} autoFocus>
          Annuler
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteAllUsersModal;
