// noinspection JSDeprecatedSymbols
import { CaseReducers }       from "@reduxjs/toolkit";
import { UserInitialState }   from "./index";
import { REQUEST_STATUS }     from "../../constants/requestStatus";
import { createUsersRoutine } from "../../actions/user.actions";
import { remove }             from "lodash";


export interface ICreateUsersInitialState {
  createUsersErrorMessage: string | null;
  createUsersRequestStatus: REQUEST_STATUS;
  enqueueCreatingUsersRequests: Array<string>;
}


export const CREATE_USERS_INITIAL_STATE: ICreateUsersInitialState = {
  createUsersErrorMessage:      null,
  createUsersRequestStatus:     REQUEST_STATUS.INITIAL,
  enqueueCreatingUsersRequests: [],
};

// noinspection JSDeprecatedSymbols
export const createUsersPartReducer: CaseReducers<UserInitialState, any> = {
  [createUsersRoutine.REQUEST]: (state, action) => {
    state.createUsersRequestStatus = REQUEST_STATUS.FETCHING;
    state.enqueueCreatingUsersRequests.push(action?.payload?.campaignId);
  },
  [createUsersRoutine.SUCCESS]: (state, action) => {
    state.createUsersRequestStatus     = REQUEST_STATUS.FETCHED;
    state.enqueueCreatingUsersRequests = remove(state.enqueueCreatingUsersRequests, action?.payload?.campaignId);
  },
  [createUsersRoutine.FAILURE]: (state, action) => {
    state.createUsersRequestStatus     = REQUEST_STATUS.FAILED;
    state.createUsersErrorMessage      = action?.payload?.message;
    state.enqueueCreatingUsersRequests = remove(state.enqueueCreatingUsersRequests, action?.payload?.campaignId);
  },
};
