import { AxiosPromise } from "axios";
import axiosInstance    from "./axiosInstance";


/**
 * Get all campaigns
 */
export function getCampaignsRequest(): AxiosPromise {
  return axiosInstance({
    url: "/campaign",
  });
}


/**
 * Get campaign details by her id
 */
export function getCampaignRequest(campaignId: string | number): AxiosPromise {
  return axiosInstance({
    url: `/campaign/${campaignId}`,
  });
}


/**
 * Create a campaign
 * @param data // TODO request model
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function putCampaignRequest(data: any): AxiosPromise {
  return axiosInstance({
    url:    "/campaign",
    method: "PUT",
    data,
  });
}


/**
 * Update a campaign
 * @param data // TODO request model
 * @param campaignId
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function patchCampaignRequest(data: any, campaignId: string | number): AxiosPromise {
  return axiosInstance({
    url:    `/campaign/${campaignId}`,
    method: "PATCH",
    data,
  });
}


/**
 * Delete campaign with her id
 */
export function deleteCampaignRequest(campaignId: string | number): AxiosPromise {
  return axiosInstance({
    url:    `/campaign/${campaignId}`,
    method: "DELETE",
  });
}


/**
 * Start a campaign
 * @param campaignId
 */
export function startCampaignRequest(campaignId: string | number): AxiosPromise {
  return axiosInstance({
    url:    `/campaign/${campaignId}/start`,
    method: "POST",
  });
}


/**
 * Direct call on a campaign
 * @param data
 * @param campaignId
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function campaignDirectCallRequest(data: { externalId: string, phoneNumber: string }, campaignId: string | number): AxiosPromise {
  return axiosInstance({
    url:    `/campaign/${campaignId}/directCall`,
    method: "POST",
    data,
  });
}


/**
 * Delete all users
 * @param campaignId
 */
export function deleteAllUsersCampaignRequest(campaignId: string | number): AxiosPromise {
  return axiosInstance({
    url: `/campaign/${campaignId}/users`,
    method: "DELETE"
  });
}
