import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ( {
  header:       {
    "background-color": "#e2001a",
  },
  toolbar:      {
    justifyContent: "space-between",
  },
  title:        {
    "font-weight":             "bold !important",
    "font-size":               "1rem !important",
    "text-transform":          "uppercase",
    display:                   "flex",
    justifyContent:            "center",
    alignItems:                "center",
    "& > .MuiButtonBase-root": {
      minWidth:           "24px", // back icon width
      height:             "38px",
      marginRight:        "8px",
      "background-color": "white",
    },
    "& > svg":                 {
      boxSizing: "unset !important",
      width:              "26px",
      height:             "26px",
      "border-radius":    "5px",
      "background-color": "white",
      padding:            "6px",
      "margin-right":     "17px",
    },
  },
  logoutButton: {
    color:              "black",
    "background-color": "white",
  },
} ));
