// noinspection JSDeprecatedSymbols
import { CaseReducers }                              from "@reduxjs/toolkit";
import { CampaignInitialState }                      from ".";
import { deleteCampaignRoutine, resetCampaignState } from "../../actions/campaign.actions";
import { REQUEST_STATUS }                            from "../../constants/requestStatus";


export interface IDeleteCampaignInitialState {
  deleteCampaignErrorMessage: string | null;
  deleteCampaignRequestStatus: REQUEST_STATUS;
}


export const DELETE_CAMPAIGN_INITIAL_STATE: IDeleteCampaignInitialState = {
  deleteCampaignErrorMessage:  null,
  deleteCampaignRequestStatus: REQUEST_STATUS.INITIAL,
};


// noinspection JSDeprecatedSymbols
export const deleteCampaignPartReducer: CaseReducers<CampaignInitialState, any> = {
  [deleteCampaignRoutine.REQUEST]: state => {
    state.deleteCampaignRequestStatus = REQUEST_STATUS.FETCHING;
  },
  [deleteCampaignRoutine.SUCCESS]: (state, action) => {
    try {
      state.deleteCampaignRequestStatus = REQUEST_STATUS.FETCHED;
      state.deleteCampaignErrorMessage  = "";
    }
    catch (e) {
      state.deleteCampaignRequestStatus = REQUEST_STATUS.FAILED;
      state.deleteCampaignErrorMessage  = e.message;
    }
  },
  [deleteCampaignRoutine.FAILURE]: (state, action) => {
    state.deleteCampaignRequestStatus = REQUEST_STATUS.FAILED;
    state.deleteCampaignErrorMessage  = action?.payload?.message;
  }
};
