// noinspection JSDeprecatedSymbols
import { CaseReducers }                                                               from "@reduxjs/toolkit";
import { CampaignInitialState }                                                                                                               from ".";
import { addUserReadCampaignState, getCampaignRoutine, removeUserReadCampaign, resetUserListReadCampaignState, updateUsersReadCampaignState } from "../../actions/campaign.actions";
import { REQUEST_STATUS }                                                                                                                     from "../../constants/requestStatus";
import { ICampaignModel }                                                             from "../../models/ICampaignModel";
import { IUserModel }                                                                 from "../../models/IUserModel";
import { ICampaignStatsModel }                                                        from "../../models/ICampaignStatsModel";
import { remove } from "lodash";


export interface IExtendedCampaignModel extends ICampaignModel { // request // TODO: model resquest
  users?: Array<IUserModel>;
  stats?: ICampaignStatsModel,
}


export interface IGetCampaignInitialState {
  readCampaign: IExtendedCampaignModel | null,
  fetchCampaignErrorMessage: string | null;
  fetchCampaignRequestStatus: REQUEST_STATUS;
}


export const GET_CAMPAIGN_INITIAL_STATE: IGetCampaignInitialState = {
  readCampaign:               {},
  fetchCampaignErrorMessage:  null,
  fetchCampaignRequestStatus: REQUEST_STATUS.INITIAL,
};


// noinspection JSDeprecatedSymbols
export const getCampaignPartReducer: CaseReducers<CampaignInitialState, any> = {
  [getCampaignRoutine.REQUEST]:        state => {
    state.fetchCampaignRequestStatus = REQUEST_STATUS.FETCHING;
  },
  [getCampaignRoutine.SUCCESS]:        (state, action) => {
    try {
      state.readCampaign = action?.payload?.campaign;
      state.fetchCampaignRequestStatus = REQUEST_STATUS.FETCHED;
    }
    catch (e) {
      state.fetchCampaignRequestStatus = REQUEST_STATUS.FAILED;
      state.fetchCampaignErrorMessage = e.message;
    }
  },
  [getCampaignRoutine.FAILURE]:        (state, action) => {
    state.fetchCampaignRequestStatus = REQUEST_STATUS.FAILED;
    state.fetchCampaignErrorMessage = action?.payload?.message;
  },
  [updateUsersReadCampaignState.type]: (state, action) => {
    if (state.readCampaign?._id === action?.payload?.campaignId && action?.payload?.users) {
      const users        = action?.payload?.users.map(obj => {
        return obj.user;
      }); // TODO: remove when receive correct entry
      console.debug({state: state.readCampaign?.users, users});
      const updatedUsers = [...(state.readCampaign?.users || []), ...users];
      state.readCampaign = { ...state.readCampaign, users: updatedUsers };
    }
  },
  [addUserReadCampaignState.type]: (state, action) => {
    const { user } = action?.payload;
    if (state.readCampaign?._id === action?.payload?.campaignId && user) {
      const users = [...(state.readCampaign?.users || [])];
      users.unshift(user);
      state.readCampaign = { ...state.readCampaign, users };
    }
  },
  [removeUserReadCampaign.type]: (state, action) => {
    const { userId } = action?.payload;
    if (state.readCampaign?._id === action?.payload?.campaignId && userId) {
      const users = [...(state.readCampaign?.users || [])];
      remove(users, { _id: userId });
      state.readCampaign = { ...state.readCampaign, users };
    }
  },
  [resetUserListReadCampaignState.type]: (state, action) => {
    if (state.readCampaign?._id === action?.payload) {
      state.readCampaign = { ...state.readCampaign, users: [] };
    }
  }
};
